import { RequestQuote } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginMiniForm from '../components/ui/LoginMiniForm';
import { DashboardLayout } from '../layout/DashboardLayout';
import JourneyLayout from '../layout/JourneyLayout';
import { isLoggedIn } from '../utils/utils';

const VendorProfileLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openLoginForm = () => {
    setIsDialogOpen(true);
  };

  const closeLoginForm = (didUserLogin = false) => {
    setIsDialogOpen(false);
    if (didUserLogin) navigate('/dashboard');
  };

  return isLoggedIn() ? (
    <DashboardLayout>{children}</DashboardLayout>
  ) : (
    <>
      <Fab
        sx={{
          position: 'fixed',
          bottom: { md: 24, xs: 72 },
          right: { md: 96, xs: 16 },
        }}
        color='primary'
        onClick={openLoginForm}
        variant='extended'
      >
        <RequestQuote sx={{ mr: 1 }} />
        Request Quote
      </Fab>
      <JourneyLayout>{children}</JourneyLayout>
      <LoginMiniForm
        open={isDialogOpen}
        handleClose={closeLoginForm}
        vendorId={children.props.vendorData?.id}
      />
    </>
  );
};

export default VendorProfileLayout;
