import { Box, Link, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import QuoteDetails from '../components/dashboard/QuoteDetails';
import noDataFound from '../Image/noDataFound.svg';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  getProposalsByUserId,
  getUserProperties,
} from '../services/CustomerService';

export default function ProjectsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [proposals, setProposals] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [installationTimeline, setInstallationTimeline] = useState('1 month');

  useEffect(() => {
    const onMount = async () => {
      try {
        const proposalsResponse = await getProposalsByUserId();
        setProposals(proposalsResponse);
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    onMount();
  }, []);

  return (
    <DashboardLayout
      announcementBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Box
        sx={{
          maxWidth: { sm: 800, xs: 400 },
          px: 2,
          pt: 2,
        }}
      >
        <Typography sx={{ fontSize: '24px', mb: '4px' }}>
          Your shortlisted offers
        </Typography>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : proposals.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 4,
            }}
          >
            <img
              src={noDataFound}
              alt='No proposals'
              style={{ width: '100%', maxWidth: '200px' }}
            />
            <Typography sx={{ mt: 2, fontSize: '18px' }}>
              Waiting for proposals from installers. <br />
              <Link href='/dashboard'>Choose a vendor from dashboard</Link> and
              arrange visit.
            </Typography>
          </Box>
        ) : (
          <QuoteDetails proposals={proposals} />
        )}
      </Box>
    </DashboardLayout>
  );
}
