import { Place, SolarPower } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../components/Logo';
import MainLayout from '../layout/MainLayout';
import { usePageTitleStore } from '../store/PageTitleStore';

const OpenOffersLandingPage = () => {
  const { updateTitle } = usePageTitleStore();

  useEffect(() => {
    updateTitle('Easiest way to go solar!');
  }, [updateTitle]);

  const navigate = useNavigate();
  const [pincode, setPincode] = React.useState('');
  const [plantSize, setPlantSize] = React.useState('');

  const [errors, setErrors] = React.useState({
    pincode: '',
    plantSize: '',
  });

  const validatePincode = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
      );
      const data = await response.json();

      if (data[0].Status === 'Success' && data[0].PostOffice?.length > 0) {
        const postOffice = data[0].PostOffice[0];
        return {
          isValid: true,
          error: '',
          district: postOffice.District,
          state: postOffice.State,
        };
      } else {
        return {
          isValid: false,
          error: 'Invalid pincode',
          district: '',
          state: '',
        };
      }
    } catch (error) {
      return {
        isValid: false,
        error: 'Error validating pincode',
        district: '',
        state: '',
      };
    }
  };

  const validateForm = async () => {
    let isValid = true;
    const newErrors = { pincode: '', plantSize: '' };
    let locationData = { district: '', state: '' };

    if (!pincode) {
      newErrors.pincode = 'PinCode is required';
      isValid = false;
    } else if (pincode.length < 6) {
      newErrors.pincode = 'PinCode must be 6 digits';
      isValid = false;
    } else {
      // Only validate pincode with API if length is correct
      const validation = await validatePincode(pincode);
      if (!validation.isValid) {
        newErrors.pincode = validation.error;
        isValid = false;
      } else {
        locationData = {
          district: validation.district,
          state: validation.state,
        };
      }
    }

    if (!plantSize) {
      newErrors.plantSize = 'Plant size is required';
      isValid = false;
    } else if (plantSize < 1 || plantSize > 99) {
      newErrors.plantSize = 'Plant size must be between 1 and 99 kW';
      isValid = false;
    }

    setErrors(newErrors);
    return { isValid, locationData };
  };

  const handleSubmit = async () => {
    const { isValid, locationData } = await validateForm();
    if (isValid) {
      navigate(
        `/open-offers?pincode=${pincode}&plantSize=${plantSize}&district=${encodeURIComponent(locationData.district)}&state=${encodeURIComponent(locationData.state)}`,
      );
    }
  };

  return (
    <MainLayout>
      <Stack
        sx={{
          minHeight: '90vh',
        }}
      >
        <Logo height={'80px'} width={'156px'} />
        <Box
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            padding: '24px',
            backgroundColor: 'white',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            width: { xs: '100%', sm: '50%' }, // Increased width for desktop
            margin: '120px',
            alignSelf: 'center',
          }}
        >
          <Typography variant='h5' fontWeight={'bold'}>
            Top <span style={{ color: 'green' }}>rated & best</span> <br />
            solar professionals!
          </Typography>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label='Pin Code'
              variant='standard'
              value={pincode}
              error={!!errors.pincode}
              helperText={errors.pincode}
              inputProps={{
                maxLength: 6,
                pattern: '[0-9]*',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Place sx={{ color: 'green' }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                if (value.length <= 6) {
                  setPincode(value);
                  setErrors((prev) => ({ ...prev, pincode: '' }));
                }
              }}
              sx={{ width: '100%' }}
            />
            <TextField
              label='Plant Size (kW)'
              variant='standard'
              type='number'
              value={plantSize}
              error={!!errors.plantSize}
              helperText={errors.plantSize}
              inputProps={{
                min: 1,
                max: 50,
                step: 1,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SolarPower />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setPlantSize(e.target.value);
                setErrors((prev) => ({ ...prev, plantSize: '' }));
              }}
              sx={{ width: '100%' }}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
              <Button
                variant='contained'
                sx={{
                  width: '100%',
                  height: '48px',
                }}
                onClick={handleSubmit}
              >
                See Local Offers
              </Button>
              <Button
                variant='outlined'
                sx={{
                  width: '100%',
                  height: '48px',
                }}
                onClick={() => navigate('/start')}
              >
                Help me choose
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </MainLayout>
  );
};

export default OpenOffersLandingPage;
