import { Box, Chip, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OffersNearYou from './OffersNearYou';
import VendorAccordion from './VendorAccordion';
import { useState, useRef } from 'react';
import EngineeringRoundedIcon from '@mui/icons-material/EngineeringRounded';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';

export default function DashboardStepper({
  propertyDetails,
  vendorData,
  recommendations,
  userDetails,
  district,
  state,
}) {
  const [selectedChip, setSelectedChip] = useState(0);

  const offersSectionRef = useRef(null);
  const installersSectionRef = useRef(null);

  const dataToShow = [
    {
      title: 'offers',
      icon: <CurrencyRupeeRoundedIcon fontSize='small' />,
      ref: offersSectionRef,
    },
    {
      title: 'installers',
      icon: <EngineeringRoundedIcon fontSize='small' />,
      ref: installersSectionRef,
    },
  ];

  const handleChipClick = (index) => {
    setSelectedChip(index);

    dataToShow[index].ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  const theme = useTheme();
  return (
    <Box>
      <Stack spacing={2}>
        <Box
          sx={{
            maxWidth: { sm: 900, xs: 400 },
          }}
        >
          <Typography variant='h5' fontWeight='bold' mb={1.5}>
            Shortlist by
          </Typography>
          <Stack spacing={2} mt={2}>
            <Stack direction='row' gap='10px' paddingBottom={'32px'}>
              {dataToShow.map((data, index) => (
                <Chip
                  label={data.title}
                  icon={data.icon}
                  key={index}
                  color={selectedChip === index ? 'primary' : 'default'}
                  onClick={() => handleChipClick(index)}
                  sx={{
                    padding: '0px 10px',
                    borderRadius: '6em',
                    backgroundColor:
                      selectedChip === index
                        ? theme.palette.primary.lighter
                        : 'white',
                    border: selectedChip === index ? 'white' : '1px solid gray',
                    color: selectedChip === index ? 'black' : 'gray',
                    '&:hover': {
                      backgroundColor: 'lightgray',
                    },
                  }}
                />
              ))}
            </Stack>

            <Box ref={offersSectionRef}>
              {recommendations.length > 0 && (
                <Box>
                  <Typography
                    fontSize={theme.typography.h5.fontSize}
                    fontWeight='bold'
                    mb={1.5}
                  >
                    Top {recommendations.length} offers
                  </Typography>

                  <OffersNearYou
                    recommendations={recommendations}
                    propertyDetails={propertyDetails}
                    userDetails={userDetails}
                    district={district}
                    state={state}
                  />
                </Box>
              )}
            </Box>

            <Box ref={installersSectionRef}>
              <Typography
                fontSize={theme.typography.h5.fontSize}
                fontWeight='bold'
                mt={2}
              >
                Top {vendorData.length} installers
              </Typography>
              <VendorAccordion
                vendorData={vendorData}
                customerId={propertyDetails?.userId}
                propertyId={propertyDetails?.id}
                siteVisitData={propertyDetails?.siteVisitData}
              />
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
