import { useTheme } from '@emotion/react';
import { Call, Check, Person } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  downloadDocument,
  sendVendorReminder,
} from '../../services/CustomerService';
import { getUserDetails } from '../../services/UserDetailsService';
import { INTERACTION } from '../../utils/app.constants';
import { Interaction } from '../../utils/app.enums';
import { trackGoogleAnalyticsEvent } from '../../utils/utils';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

export default function QuoteDetails({ proposals }) {
  const theme = useTheme();
  const [reminderSent, setReminderSent] = useState(proposals.map(() => null));
  const navigate = useNavigate();

  const handleDownload = async (docId) => {
    const data = await downloadDocument(docId);
    const link = document.createElement('a');
    const url = data;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const calculatePayableCost = (installationCost, netMeteringCost, gst) => {
    return Math.round(
      installationCost + (installationCost * gst) / 100 + netMeteringCost,
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      hour: 'numeric',
      hour12: true,
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    let formattedDate = date.toLocaleString('en-IN', options);
    formattedDate = formattedDate.split(' ');
    formattedDate.slice(-2, 0, ',');
    formattedDate = formattedDate.join(' ');
    return formattedDate;
  }

  const handleReminder = async (matchId, vendorId, index) => {
    setReminderSent((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
    await sendVendorReminder(matchId);
    setReminderSent((prevState) => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
    trackGoogleAnalyticsEvent(
      INTERACTION,
      Interaction.SEND_REMINDER_CUSTOMER_VENDOR_PROPOSAL,
      {
        source: getUserDetails()?.id,
        target: vendorId,
        createdAt: new Date(),
      },
    );
  };

  const trackCallGoogleAnalyticsEvent = (vendorId) => {
    trackGoogleAnalyticsEvent(
      INTERACTION,
      Interaction.CUSTOMER_INITIATED_CALL,
      {
        source: getUserDetails().id,
        target: vendorId,
        createdAt: new Date(),
      },
    );
  };

  const openVendorProfile = (proposal) => {
    const vendorNameEncoded = proposal?.vendor?.establishmentName.replace(
      /\s+/g,
      '-',
    );
    const vendorInfo = `${vendorNameEncoded}_${proposal?.vendor?.id}`;
    navigate(`/vendor-profile/${vendorInfo}`);
  };

  return (
    <Box pt={2}>
      {proposals.map((proposal, index) => (
        <Box key={index} mb={1.5}>
          <Accordion
            sx={{
              paddingInline: { sm: '20px', xs: '2px' },
              borderRadius: '9px !important',
              boxShadow: 'none',
              '.MuiAccordionSummary-root': {
                alignItems: 'start',
              },
              '.MuiAccordionSummary-expandIconWrapper': {
                margin: '13px 0',
                marginLeft: '4px',
              },
              '&:before': {
                height: '0',
              },
              '&.Mui-expanded': {
                boxShadow: 'unset',
                margin: '0',
              },
              backgroundColor: theme.palette.secondary.lighter,
            }}
            expanded={true}
          >
            <AccordionSummary
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Stack>
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {proposal?.vendor?.establishmentName}
                </Typography>
                <Typography
                  fontWeight='500'
                  variant='caption'
                  component={'p'}
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {proposal.proposal ? (
                    <>Latest shared on {formatDate(proposal?.createdAt)}</>
                  ) : (
                    'Waiting for proposal'
                  )}
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingTop: 0,
              }}
            >
              {proposal.proposal && (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      fontWeight={'bold'}
                      component={'p'}
                      pb={'22px'}
                    >
                      Price details
                    </Typography>
                  </Grid>
                  <Grid item xs={8} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        textAlign: 'left',
                      }}
                    >
                      Payable for {proposal?.proposal?.plantInfo?.plantSize} kW{' '}
                      {proposal?.proposal?.plantInfo?.plantType?.toLowerCase()}{' '}
                      plant
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        textAlign: 'right',
                      }}
                    >
                      ₹
                      {calculatePayableCost(
                        proposal?.proposal?.pricing?.installationCost,
                        proposal?.proposal?.pricing?.netMeteringCost,
                        proposal?.proposal?.pricing?.gst,
                      ).toLocaleString('en-IN')}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        textAlign: 'left',
                      }}
                    >
                      State Subsidy
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        color: theme.palette.primary.main,
                        textAlign: 'right',
                      }}
                    >
                      -₹
                      {(
                        proposal?.proposal?.pricing?.applicableCentralSubsidy +
                        proposal?.proposal?.pricing?.applicableStateSubsidy
                      ).toLocaleString('en-IN')}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        textAlign: 'left',
                      }}
                    >
                      Central Subsidy
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pb={'9px'}>
                    <Typography
                      variant='subtitle2'
                      component={'p'}
                      sx={{
                        color: theme.palette.primary.main,
                        textAlign: 'right',
                      }}
                    >
                      -₹
                      {(
                        proposal?.proposal?.pricing?.applicableCentralSubsidy +
                        proposal?.proposal?.pricing?.applicableStateSubsidy
                      ).toLocaleString('en-IN')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={'7px'} pb={'16px'}>
                    <Divider />
                  </Grid>
                  <Grid item xs={8} pb={'9px'}>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                      }}
                    >
                      Total (INR)
                    </Typography>
                  </Grid>
                  <Grid item xs={4} pb={'9px'}>
                    <Typography
                      variant='body2'
                      sx={{
                        fontWeight: 'bold',
                        textAlign: 'right',
                      }}
                    >
                      ₹
                      {proposal?.proposal?.pricing?.totalCost.toLocaleString(
                        'en-IN',
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Stack
                direction={'row'}
                pt={2}
                gap={{ sm: 2, xs: 1.5 }}
                mt={2}
                justifyContent={'flex-end'}
                alignItems={'center'}
                flexWrap={{ sm: 'nowrap', xs: 'wrap-reverse' }}
                ml={'auto'}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  disableElevation
                  startIcon={<Person />}
                  onClick={() => openVendorProfile(proposal)}
                  fullWidth
                  size='small'
                >
                  View Profile
                </Button>
                {proposal?.documentId && (
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<DownloadIcon />}
                    disableElevation
                    fullWidth
                    size='small'
                    onClick={() => handleDownload(proposal?.documentId)}
                  >
                    Download Quote
                  </Button>
                )}
                {proposal?.proposal && (
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<CompareArrowsIcon />}
                    disableElevation
                    fullWidth
                    size='small'
                    onClick={() => {
                      sessionStorage.setItem(
                        'currentCreatedAt',
                        proposal?.createdAt,
                      );
                      navigate('/proposals');
                    }}
                  >
                    See Details
                  </Button>
                )}
                {!proposal.proposal && (
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    fullWidth
                    size='small'
                    onClick={() =>
                      handleReminder(
                        proposal?.matchId,
                        proposal?.vendor?.id,
                        index,
                      )
                    }
                    startIcon={
                      reminderSent[index] === null ? (
                        <NotificationsActiveIcon />
                      ) : reminderSent[index] ? (
                        <Check />
                      ) : (
                        <CircularProgress size={'16px'} />
                      )
                    }
                    disabled={reminderSent[index]}
                  >
                    {reminderSent[index] ? 'Reminder sent' : 'Send reminder'}
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  );
}
