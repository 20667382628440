import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  getProposalsByUserId,
  getUserProperties,
} from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { InstallationTimeline } from '../utils/app.enums';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrangeVisitDialog from '../components/ui/ArrangeVisitDialog';
import { getUserDetails } from '../services/UserDetailsService';
const Proposals = () => {
  const [userDetails, setUserDetails] = useState({});
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [selectedRecommendationId, setSelectedRecommendationId] =
    useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [arrangedVisits, setArrangedVisits] = useState({});
  const { updateTitle, updateDescription } = usePageTitleStore();
  const [propertyDetails, setPropertyDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');
  // const { setIsSearchOver, setIsLooking } = useSearchAnimate();
  const [offersLoading, setOffersLoading] = useState(true);
  const theme = useTheme();
  const [proposals, setProposals] = useState({});
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [establishment1, setEstablishment1] = useState(0);
  const [establishment2, setEstablishment2] = useState(null);

  const [selectedCreatedAt1, setSelectedCreatedAt1] = useState('');
  const [selectedCreatedAt2, setSelectedCreatedAt2] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const currentCreatedAt = sessionStorage.getItem('currentCreatedAt') || null;

  useEffect(() => {
    if (Array.isArray(proposals) && currentCreatedAt) {
      const selectedIndex = proposals.findIndex(
        (proposal) => proposal?.createdAt === currentCreatedAt,
      );
      setEstablishment1(selectedIndex);
    }
  }, [proposals]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange1 = (event) => {
    const selectedValue = event.target.value;

    const selectedIndex = proposals?.findIndex(
      (proposal) => proposal?.createdAt === selectedValue,
    );
    setSelectedCreatedAt1(proposals[selectedIndex]?.createdAt);
    setEstablishment1(selectedIndex);
  };

  const handleChange2 = (event) => {
    const selectedValue = event.target.value;
    const selectedIndex = proposals?.findIndex(
      (proposal) => proposal?.createdAt === selectedValue,
    );
    setSelectedCreatedAt2(proposals[selectedIndex]?.createdAt);
    setEstablishment2(selectedIndex);
  };

  const renderPlantInfo = (proposal) => (
    <Typography mb={1}>
      {proposal?.proposal?.plantInfo?.plantSize ? (
        <b>
          {proposal?.proposal?.plantInfo?.plantSize} kw
          <br />
        </b>
      ) : null}
      {proposal?.proposal?.plantInfo?.plantType?.charAt(0) +
        proposal?.proposal?.plantInfo?.plantType?.slice(1).toLowerCase()}
      <br />
      {proposal?.proposal?.plantInfo?.panelType}
      <br />
    </Typography>
  );

  useEffect(() => {
    updateTitle('Proposals | VaySolar');
    updateDescription(
      'VaySolar a platform for vendor and customer to unite world with solar power energy',
    );
  }, [updateDescription, updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
        if (
          data[0]?.installationTimeline === InstallationTimeline.NOT_LOOKING
        ) {
          // setIsSearchOver(false);
          // setIsLooking(false);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    onMount();
  }, []);

  useEffect(() => {
    const onMount = async () => {
      const res = await getProposalsByUserId();
      const filteredProposals = res.filter((item) => item?.proposal);
      setProposals(filteredProposals);
      setOffersLoading(false);
    };
    onMount();
  }, []);

  useEffect(() => {
    const onMount = async () => {
      const userDetails = getUserDetails();
      setUserDetails(userDetails);
    };
    onMount();
  }, []);
  if (isLoading || offersLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        sx={{
          flexGrow: 1,
          px: { sm: 3, xs: 1.5 },
          py: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 8 },
        }}
      >
        <CircularProgress color='primary' />
      </Box>
    );
  }

  const renderMenuItems = (options) => {
    return options?.map((option) => {
      const installationCost = option?.proposal?.pricing?.installationCost || 0;
      const gst = option?.proposal?.pricing?.gst || 0;
      const stateSubsidy =
        option?.proposal?.pricing?.applicableStateSubsidy || 0;
      const centralSubsidy =
        option?.proposal?.pricing?.applicableCentralSubsidy || 0;
      const payable =
        installationCost +
        (installationCost * gst) / 100 -
        stateSubsidy -
        centralSubsidy;
      if (!option?.proposal) {
        return null;
      }
      return (
        <MenuItem key={option.createdAt} value={option.createdAt}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ width: isMobile ? 'unset' : '8rem' }}
            >
              <Typography
                fontSize={theme.typography.body2.fontSize}
                fontWeight={'bold'}
              >
                {option?.proposal?.plantInfo?.plantSize} kW{' '}
                {option?.proposal?.plantInfo?.plantType
                  ?.charAt(0)
                  .toUpperCase()}
                {option?.proposal?.plantInfo?.plantType
                  ?.slice(1)
                  .toLowerCase()}{' '}
                <br />
              </Typography>
              <Typography fontSize={theme.typography.caption.fontSize}>
                by {option?.vendor?.establishmentName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ width: isMobile ? 'unset' : '8rem' }}
            >
              <Typography
                fontSize={theme.typography.body2.fontSize}
                fontWeight={'bold'}
              >
                ₹{new Intl.NumberFormat('en-IN').format(Math.trunc(payable))}
                <br />
              </Typography>
              <Typography
                fontSize={theme.typography.caption.fontSize}
                color={theme.palette.gray}
              >
                on &nbsp;
                {new Date(option?.createdAt).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                })}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
      );
    });
  };

  const installationCost =
    proposals[0]?.proposal?.pricing?.installationCost || 0;
  const gst = proposals[0]?.proposal?.pricing?.gst || 0;
  const stateSubsidy =
    proposals[0]?.proposal?.pricing?.applicableStateSubsidy || 0;
  const centralSubsidy =
    proposals[0]?.proposal?.pricing?.applicableCentralSubsidy || 0;
  const payable =
    installationCost +
    (installationCost * gst) / 100 -
    stateSubsidy -
    centralSubsidy;
  const openArrangeSiteVisit = (vendorId, configId, recommendationId) => {
    setSelectedVendorId(vendorId);
    setSelectedConfigId(configId);
    setSelectedRecommendationId(recommendationId);
    setIsDialogOpen(true);
  };
  const closeArrangeSiteVisit = (isVisitCreated) => {
    setIsDialogOpen(false);
    setSelectedVendorId(null);
    setSelectedConfigId(null);
    if (isVisitCreated) {
      setArrangedVisits({
        ...arrangedVisits,
        [selectedRecommendationId]: true,
      });
    }
  };
  console.log(
    'roposals[establishment1]?.proposal?.paymentTerms',
    proposals[establishment1]?.proposal?.paymentTerms,
  );

  return (
    <DashboardLayout
      announcementBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Stack gap={1} m={1} color={theme.palette.primary.main}>
        <Typography
          color={theme.palette.text.primary}
          fontSize={theme.typography.h5.fontSize}
          fontWeight='bold'
          mb={2}
        >
          Rooftop solar quotes
        </Typography>
        {proposals?.length > 1 ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                size='small'
                borderRadius='24px'
                sx={{ width: isMobile ? '90%' : '100%', height: '100%' }}
              >
                <Select
                  labelId='establishmentName1-label'
                  id='establishmentName1'
                  value={selectedCreatedAt1 || currentCreatedAt}
                  onChange={handleChange1}
                  sx={{
                    height: 'inherit',
                  }}
                >
                  {renderMenuItems(
                    proposals.filter(
                      (option) => option?.createdAt !== selectedCreatedAt2,
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                size='small'
                sx={{
                  width: isMobile ? '90%' : '100%',
                  height: '100%',
                  borderRadius: '24px',
                }}
              >
                <InputLabel id='demo-simple-select-label'>
                  Compare with
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='selectedCreatedAt2'
                  value={selectedCreatedAt2 || ''}
                  onChange={handleChange2}
                  label='Compare with'
                  sx={{
                    height: 'inherit',
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {renderMenuItems(
                    proposals.filter(
                      (option) => option?.createdAt !== selectedCreatedAt1,
                    ),
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            {proposals
              .filter((option) => option?.createdAt === selectedCreatedAt1)
              .map((proposal) => (
                <React.Fragment key={proposal.id}>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={theme.typography.h6.fontSize}
                      fontWeight='bold'
                    >
                      {proposal?.proposal?.plantInfo?.plantSize} kW{' '}
                      {proposal?.proposal?.plantInfo?.plantType
                        ?.charAt(0)
                        .toUpperCase()}
                      {proposal?.proposal?.plantInfo?.plantType
                        ?.slice(1)
                        .toLowerCase()}{' '}
                      <br />
                    </Typography>
                    <Typography fontSize={theme.typography.caption.fontSize}>
                      by {proposal?.vendor?.establishmentName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      fontSize={theme.typography.h6.fontSize}
                      fontWeight='bold'
                    >
                      ₹
                      {new Intl.NumberFormat('en-IN').format(
                        Math.trunc(payable),
                      )}
                      <br />
                    </Typography>
                    <Typography
                      fontSize={theme.typography.caption.fontSize}
                      color={theme.palette.gray}
                    >
                      on &nbsp;
                      {new Date(proposal?.createdAt).toLocaleDateString(
                        'en-GB',
                        {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        },
                      )}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
          </Grid>
        )}
        {proposals[establishment1]?.proposal?.plantInfo ||
        proposals[establishment2]?.proposal?.plantInfo ? (
          <Stack gap={0.8} mt={2}>
            <Typography
              fontSize={theme.typography.h6.fontSize}
              fontWeight='bold'
            >
              Basic information
            </Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {proposals[establishment1]?.proposal?.plantInfo
                  ? renderPlantInfo(proposals[establishment1])
                  : null}
              </Grid>
              <Grid item xs={6}>
                {proposals[establishment2] &&
                proposals[establishment2]?.proposal?.plantInfo
                  ? renderPlantInfo(proposals[establishment2])
                  : null}
              </Grid>
            </Grid>
          </Stack>
        ) : null}
        {proposals[establishment1]?.proposal?.pricing ||
        proposals[establishment2]?.proposal?.pricing ? (
          <Stack gap={0.8} mb={2}>
            <Typography
              fontSize={theme.typography.h6.fontSize}
              fontWeight='bold'
              display={'flex'}
              alignItems={'center'}
            >
              Payable &nbsp;
              <div>
                <IconButton onClick={handleClick}>
                  <HelpIcon sx={{ color: 'black' }} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Typography sx={{ p: 2 }} maxWidth={'14rem'}>
                    Subsidies are credited to your bank account directly
                    after installation.
                  </Typography>
                </Popover>
              </div>
            </Typography>
            <Divider />
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                {[establishment1, establishment2 && establishment2]?.map(
                  (index) => {
                    const option = proposals[index];
                    const installationCost =
                      option?.proposal?.pricing?.installationCost || 0;
                    const gst = option?.proposal?.pricing?.gst || 0;
                    const stateSubsidy =
                      option?.proposal?.pricing?.applicableStateSubsidy || 0;
                    const centralSubsidy =
                      option?.proposal?.pricing?.applicableCentralSubsidy || 0;

                    const payable =
                      installationCost +
                      (installationCost * gst) / 100 -
                      stateSubsidy -
                      centralSubsidy;
                    const perKw =
                      payable / option?.proposal?.plantInfo?.plantSize / 1000;

                    return (
                      <Grid item xs={6} key={index}>
                        <Typography>
                          {payable ? (
                            <>
                              <b>
                                ₹{' '}
                                {new Intl.NumberFormat('en-IN').format(
                                  Math.trunc(payable),
                                )}
                              </b>
                              <br />₹{' '}
                              {new Intl.NumberFormat('en-IN').format(
                                Math.trunc(perKw),
                              )}{' '}
                              k per kw
                            </>
                          ) : null}
                        </Typography>
                      </Grid>
                    );
                  },
                )}
              </Grid>
            </Box>
          </Stack>
        ) : null}
        {proposals[establishment1]?.proposal?.materials ||
        proposals[establishment2]?.proposal?.materials ? (
          <Accordion
            defaultExpanded
            sx={{
              border: '1px solid lightGray',
              color: theme.palette.primary.main,
              borderRadius: '9px !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography
                fontSize={theme.typography.h6.fontSize}
                color={theme.palette.text.primary}
                fontWeight='bold'
              >
                Materials
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {[
                'SOLAR PANEL',
                'SOLAR BATTERY',
                'MODULE MOUNTING STRUCTURE',
                'ARRAY JUNCTION BOX',
                'AC DISTRIBUTION BOARD',
                'SOLAR METER',
                'NET METER',
                'DC CABLES',
                'AC CABLES',
                'EARTHING',
                'LIGHTNING ARRESTOR',
                'EARTHING CABLES',
                'ACCESSORIES',
                'INVERTER',
                'STRUCTURE',
                'CABLE',
                'OTHER',
              ]?.map((category, idx) =>
                proposals[establishment1]?.proposal?.materials?.some(
                  (material) => material?.product === category,
                ) ||
                proposals[establishment2]?.proposal?.materials?.some(
                  (material) => material?.product === category,
                ) ? (
                  <Stack key={idx} sx={{ flexGrow: 1 }} gap={0.8}>
                    <Typography
                      fontSize={theme.typography.h6.fontSize}
                      fontWeight='bold'
                    >
                      {category.charAt(0) + category.slice(1).toLowerCase()}
                    </Typography>
                    <Divider />
                    <Grid container spacing={2} mb={2}>
                      {[
                        proposals[establishment1],
                        proposals[establishment2] && proposals[establishment2],
                      ]?.map((proposal, index) => (
                        <Grid item xs={6} key={index}>
                          {proposal?.proposal?.materials
                            ?.filter(
                              (material) => material?.product === category,
                            )
                            .map((option) => (
                              <Typography key={option.id} mb={1}>
                                {option?.description} x {option?.quantity}
                                <br />
                                {option?.brand}
                                <br />
                                {option?.warrantyInYears} yr warranty
                                <br />
                              </Typography>
                            ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Stack>
                ) : null,
              )}
            </AccordionDetails>
          </Accordion>
        ) : null}
        {proposals[establishment1]?.proposal?.paymentTerms ||
        proposals[establishment2]?.proposal?.paymentTerms ? (
          <Accordion
            defaultExpanded
            sx={{
              border: '1px solid lightGray',
              color: theme.palette.primary.main,
              borderRadius: '9px !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography
                fontSize={theme.typography.h6.fontSize}
                color={theme.palette.text.primary}
                fontWeight={'bold'}
              >
                Payment details
              </Typography>
            </AccordionSummary>
            <Divider />

            <AccordionDetails>
              <Stack sx={{ flexGrow: 1 }} gap={'10px'}>
                {[
                  'beforeInstallationBegins',
                  'beforeMaterialDispatch',
                  'orderConfirmation',
                  'afterCommissioning',
                ]?.map((term, idx) => (
                  <Grid container spacing={2} key={idx}>
                    {[establishment1, establishment2 && establishment2]?.map(
                      (establishment, index) => {
                        const installationCost =
                          proposals[establishment]?.proposal?.pricing
                            ?.installationCost || 0;
                        const gst =
                          proposals[establishment]?.proposal?.pricing?.gst || 0;
                        const stateSubsidy =
                          proposals[establishment]?.proposal?.pricing
                            ?.applicableStateSubsidy || 0;
                        const centralSubsidy =
                          proposals[establishment]?.proposal?.pricing
                            ?.applicableCentralSubsidy || 0;
                        const payable =
                          installationCost +
                          (installationCost * gst) / 100 -
                          stateSubsidy -
                          centralSubsidy;

                        return (
                          <Grid item xs={6} key={index}>
                            {proposals[establishment]?.proposal?.paymentTerms?.[
                              term
                            ] ? (
                              <Typography mb={1}>
                                {term === 'beforeInstallationBegins' &&
                                  'Advance'}
                                {term === 'beforeMaterialDispatch' &&
                                  'Before materials despatch'}
                                {term === 'orderConfirmation' &&
                                  'After materials despatch'}
                                {term === 'afterCommissioning' &&
                                  'After commissioning'}
                                <br />
                                <b>
                                  {
                                    proposals[establishment].proposal
                                      .paymentTerms[term]
                                  }
                                  % - ₹{' '}
                                  {new Intl.NumberFormat('en-IN').format(
                                    Math.trunc(
                                      (proposals[establishment].proposal
                                        .paymentTerms[term] *
                                        payable) /
                                        100,
                                    ),
                                  )}
                                </b>
                              </Typography>
                            ) : null}
                          </Grid>
                        );
                      },
                    )}
                  </Grid>
                ))}
              </Stack>

              <Stack gap={1} mt={3}>
                <Typography
                  fontSize={theme.typography.h6.fontSize}
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  Subsidy
                </Typography>
                <Divider />
                <Grid container spacing={2}>
                  {[establishment1, establishment2 && establishment2]?.map(
                    (establishment, idx) => (
                      <Grid item xs={6} key={idx}>
                        {proposals[establishment]?.proposal?.pricing
                          ?.applicableCentralSubsidy ||
                        proposals[establishment]?.proposal?.pricing
                          ?.applicableStateSubsidy ? (
                          <Typography mb={1}>
                            Before Installation <br />
                            <b>-</b>
                          </Typography>
                        ) : null}
                      </Grid>
                    ),
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {[establishment1, establishment2 && establishment2]?.map(
                    (establishment, idx) => (
                      <Grid item xs={6} key={idx}>
                        {proposals[establishment]?.proposal?.pricing
                          ?.applicableCentralSubsidy ||
                        proposals[establishment]?.proposal?.pricing
                          ?.applicableStateSubsidy ? (
                          <Typography mb={1}>
                            After Installation <br />
                            <b>
                              INR{' '}
                              {proposals[establishment]?.proposal?.pricing
                                ?.applicableCentralSubsidy / 1000}
                              K (Central) <br />
                              INR{' '}
                              {proposals[establishment]?.proposal?.pricing
                                ?.applicableStateSubsidy / 1000}
                              K (State)
                            </b>
                          </Typography>
                        ) : null}
                      </Grid>
                    ),
                  )}
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        ) : null}
        {proposals[establishment1]?.proposal?.pricing?.installationCost ||
        proposals[establishment2]?.proposal?.pricing?.installationCost ? (
          <Accordion
            defaultExpanded
            sx={{
              border: '1px solid lightGray',
              color: theme.palette.primary.main,
              borderRadius: '9px !important',
              boxShadow: 'none',
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
            >
              <Typography
                fontSize={theme.typography.h6.fontSize}
                color={theme.palette.text.primary}
                fontWeight={'bold'}
              >
                Net cost
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  {[establishment1, establishment2 && establishment2]?.map(
                    (index) => {
                      const proposal = proposals[index];
                      const installationCost =
                        proposal?.proposal?.pricing?.installationCost || 0;
                      const stateSubsidy =
                        proposal?.proposal?.pricing?.applicableStateSubsidy ||
                        0;
                      const centralSubsidy =
                        proposal?.proposal?.pricing?.applicableCentralSubsidy ||
                        0;
                      const totalSubsidy = stateSubsidy + centralSubsidy;
                      const finalCost = installationCost - totalSubsidy;

                      return (
                        <Grid item xs={6} key={index}>
                          {installationCost ? (
                            <Typography>
                              <b>
                                ₹{' '}
                                {new Intl.NumberFormat('en-IN').format(
                                  Math.trunc(installationCost),
                                )}
                              </b>
                              <br />
                              (-){' '}
                              {new Intl.NumberFormat('en-IN').format(
                                Math.trunc(totalSubsidy),
                              )}
                              <br />
                              <br />
                              <b>
                                ₹
                                {new Intl.NumberFormat('en-IN').format(
                                  Math.trunc(finalCost),
                                )}
                              </b>
                            </Typography>
                          ) : null}
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Stack gap={2} mt={4}>
          <Typography
            fontSize={theme.typography.h6.fontSize}
            color={theme.palette.text.primary}
            fontWeight={'bold'}
            lineHeight={'0.8rem'}
          >
            Schedule visit
          </Typography>
          <Divider />
          <Grid container>
            <Grid item xs={6}>
              {proposals[establishment1]?.proposal ? (
                <Button
                  onClick={() =>
                    openArrangeSiteVisit(
                      proposals[establishment1]?.vendor?.id,
                      proposals[establishment1]?.proposalConfig?.id,
                      proposals[establishment1]?.id,
                    )
                  }
                  variant='contained'
                >
                  Call for visit
                </Button>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              {proposals[establishment2]?.proposal ? (
                <Button
                  onClick={() =>
                    openArrangeSiteVisit(
                      proposals[establishment2]?.vendor?.id,
                      proposals[establishment2]?.proposalConfig?.id,
                      proposals[establishment2]?.id,
                    )
                  }
                  variant='contained'
                >
                  Call for visit
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <ArrangeVisitDialog
        open={isDialogOpen}
        handleClose={closeArrangeSiteVisit}
        propertyDetails={propertyDetails}
        vendorId={selectedVendorId}
        userDetails={userDetails}
        configId={selectedConfigId}
        recommendationId={selectedRecommendationId}
      />
    </DashboardLayout>
  );
};
export default Proposals;
