import { Edit, Security } from '@mui/icons-material';
import { ChartsReferenceLine } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardStepper from '../components/dashboard/DashboardStepper';
import PlantSize from '../components/dashboard/PlantSize';
import SolarSearch from '../components/dashboard/SolarSearch';
import EditPlantDetailsDialog from '../components/ui/EditPlantDetailsDialog';
import { DashboardLayout } from '../layout/DashboardLayout';
import {
  generateRecommendations,
  getProposalsByUserId,
  getRecommendations,
  getUserProperties,
  updatePlantSize,
  updatePlantType,
} from '../services/CustomerService';
import { getUserDetails } from '../services/UserDetailsService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { useSearchAnimate } from '../store/SearchAnimateStore';
import { InstallationTimeline } from '../utils/app.enums';
import { toSentenceCase } from '../utils/utils';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import EnergySavingsLeafRoundedIcon from '@mui/icons-material/EnergySavingsLeafRounded';
import CloseIcon from '@mui/icons-material/Close';
import { getProjectEstimate } from '../services/CustomerService';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';

const isActivelyLooking = (installationTimeline) => {
  const timelines = ['1 month', '1-3 months', '3+ months'];
  return timelines.includes(installationTimeline);
};
const options = [
  {
    id: 1,
    title: 'Cost of solar',
    name: '₹45000 to 68000 per kW',
    icon: (
      <CurrencyRupeeIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details: 'Depends on size, technology, brands, structure, warranties',
    href: 'https://www.vaysolar.com/docs/research/cost/',
    production: '10 units',
  },
  {
    id: 2,
    title: 'Benefits of solar',
    name: '₹2-3 per unit (up to 75% less)',
    icon: (
      <EnergySavingsLeafIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details:
      "You can reduce your electricity bills by up to 90%. Property's value also increases in the market!",
    href: 'https://www.vaysolar.com/docs/research/benefits/',
    production: '20 units',
  },
  {
    id: 3,
    title: 'How solar works?',
    name: 'Cells create energy from sunlight',
    icon: (
      <SolarPowerIcon
        fontSize='large'
        sx={{
          color: 'primary.main',
        }}
      />
    ),
    details: 'Solar energy is the cheapest form of energy source available',
    href: 'https://www.vaysolar.com/docs/research/how-it-works/',
    production: '40 units',
  },
];

const DashboardPage = () => {
  const { updateTitle, updateDescription } = usePageTitleStore();
  const [vendorData, setVendorData] = useState({});
  const [propertyDetails, setPropertyDetails] = useState({});
  const [location, setLocation] = useState({ district: '', state: '' });
  const [proposals, setProposals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [installationTimeline, setInstallationTimeline] = useState('1 month');
  const { setIsSearchOver, setIsLooking } = useSearchAnimate();
  const [recommendations, setRecommendations] = useState([]);
  const [showPlantSizeSelection, setShowPlantSizeSelection] = useState(true);
  const [lookingForSolar, setLookingForSolar] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [isPlantDetailsEditDialogOpen, setIsPlantDetailsEditDialogOpen] =
    useState(false);
  const [offersLoading, setOffersLoading] = useState(true);
  const [benefitDialogOpen, setBenefitDialogOpen] = useState(false);
  const [dataField, setDataField] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [status, setStatus] = useState(installationTimeline);
  useEffect(() => {
    setStatus(installationTimeline);
  }, [installationTimeline]);
  const getBenefitsData = async () => {
    try {
      const region = propertyDetails.address?.state || 'Delhi';
      const sanctionedLoad = propertyDetails.sanctionedLoad || 3;
      const propertyType = propertyDetails.propertyType || 'RESIDENTIAL';
      const billAmount = propertyDetails.billAmount || 2000;
      const billMonth = propertyDetails.billMonth || 'JANUARY';
      const shadowFreeArea = propertyDetails.shadowFreeArea || 0;
      const plantSize = propertyDetails.plantSize || null;
      const data = await getProjectEstimate(
        region,
        propertyType,
        sanctionedLoad,
        billAmount,
        shadowFreeArea,
        billMonth,
        plantSize,
      );
      if (data.length === 0) throw new Error('No data found');
      const transformedData = data;
      setDataField(transformedData);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (propertyDetails) {
      getBenefitsData();
    }
  }, []);
  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  const handlePlantDetailsEditDialogOpen = () => {
    setIsPlantDetailsEditDialogOpen(true);
  };

  const handlePlantDetailsEditDialogClose = () => {
    setIsPlantDetailsEditDialogOpen(false);
  };

  const handlePlantDetailsEditDialogSave = async (plantDetails) => {
    setIsLoading(true);
    try {
      await updatePlantSize(propertyDetails?.id, plantDetails.plantSize);
      await updatePlantType(propertyDetails?.id, plantDetails.plantType);
      setPropertyDetails({
        ...propertyDetails,
        plantSize: plantDetails.plantSize,
        plantType: plantDetails.plantType,
      });
      setRecommendationsAndVendorsData(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const setRecommendationsAndVendorsData = async (
    refreshRecommendations = false,
  ) => {
    setOffersLoading(true);
    if (refreshRecommendations) {
      await generateRecommendations();
    }
    let recommendationsResponse = await getRecommendations();
    if (recommendationsResponse.length === 0) {
      await generateRecommendations();
      recommendationsResponse = await getRecommendations();
    }
    const recommendations = recommendationsResponse.filter(
      (recommendation) => recommendation.proposalConfig,
    );
    const vendorList = recommendationsResponse
      .filter((recommendation) => !recommendation.proposalConfig)
      .map((recommendation) => {
        return {
          ...recommendation?.vendor,
          recommendationId: recommendation.id,
        };
      });
    setVendorData(vendorList);
    setRecommendations(recommendations);
    setOffersLoading(false);
  };

  useEffect(() => {
    updateTitle('Dashboard | VaySolar');
    updateDescription(
      'VaySolar a platform for vendor and customer to unite world with solar power energy',
    );
  }, [updateDescription, updateTitle]);

  useEffect(() => {
    const onMount = async () => {
      try {
        const userDetails = getUserDetails();
        setUserDetails(userDetails);
        const data = await getUserProperties();
        setPropertyDetails(data[0]);
        setInstallationTimeline(data[0]?.installationTimeline);
        if (
          data[0]?.installationTimeline === InstallationTimeline.NOT_LOOKING
        ) {
          setIsSearchOver(false);
          setIsLooking(false);
          setLookingForSolar(false);
        }
        if (data[0]?.plantSize) {
          setShowPlantSizeSelection(false);
        }
        if (
          data[0]?.plantSize &&
          data[0]?.installationTimeline !== InstallationTimeline.NOT_LOOKING
        ) {
          const proposalsResponse = await getProposalsByUserId();
          setProposals(proposalsResponse);
          await setRecommendationsAndVendorsData();
          if (data[0]?.pinCode) {
            const response = await fetch(
              `https://api.postalpincode.in/pincode/${data[0].pinCode}`,
            );
            const pinCodeResponse = await response.json();
            if (pinCodeResponse[0].Status === 'Success') {
              const { District, State } = pinCodeResponse[0].PostOffice[0];
              setLocation({ district: District, state: State });
            }
          }
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
        setOffersLoading(false);
      }
    };
    onMount();
  }, []);

  if (isLoading || offersLoading) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='100vh'
        sx={{
          flexGrow: 1,
          px: { sm: 3, xs: 1.5 },
          py: { sm: 3, xs: 2 },
          pb: { sm: 3, xs: 8 },
        }}
      >
        <CircularProgress color='primary' />
      </Box>
    );
  }

  const BenefitsDialog = ({
    open,
    handleClose,
    dataField,
    propertyDetails,
  }) => {
    console.log('new1', dataField);

    const [selectedChip, setSelectedChip] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dataToShow = [
      {
        title: 'Summary',
        icon: <SummarizeOutlinedIcon fontSize='small' />,
      },
      {
        title: 'First year',
        icon: <TrendingUpOutlinedIcon fontSize='small' />,
      },
      {
        title: 'Lifetime',
        icon: <TrendingUpOutlinedIcon fontSize='small' />,
      },
    ];

    const LastYearData = () => {
      return (
        <Stack pt={'10px'}>
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data:
                  dataField !== ''
                    ? Object.values(
                        dataField.data.noLoanLifetimeProjection.year,
                      )
                    : [],
              },
            ]}
            series={[
              {
                data:
                  dataField !== ''
                    ? Object.values(
                        dataField.data.noLoanLifetimeProjection
                          .cumulativeSavings,
                      )
                    : [],
                label: 'Cumulative savings',
                color: '#7CC674',
              },
            ]}
            height={300}
            leftAxis={null}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'top', horizontal: 'right' },
                labelStyle: {
                  fontSize: '13px',
                },
                padding: 0,
                itemMarkWidth: 13,
                itemMarkHeight: 13,
              },
            }}
          >
            {dataField !== '' && (
              <ChartsReferenceLine
                y={dataField.data.noLoanLifetimeProjection.costOfProject['0']}
                label='Project cost'
                labelStyle={{ fontSize: '12px' }}
                labelAlign='start'
              />
            )}
          </BarChart>
          <TableContainer
            component={'div'}
            sx={{
              paddingInline: { sm: '16px', xs: '3px' },
            }}
          >
            <Table
              sx={{ minWidth: '100%' }}
              aria-label='simple table'
              size='small'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Year
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Generation
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Savings
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Total savings
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataField !== '' &&
                  Object.values(
                    dataField.data.noLoanLifetimeProjection.year,
                  ).map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                      >
                        {row}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                      >
                        {Math.round(
                          dataField.data.noLoanLifetimeProjection
                            .units_produced_solar[`${index}`],
                        ).toLocaleString('en-IN')}{' '}
                        units
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                      >
                        ₹{' '}
                        {Math.round(
                          dataField.data.noLoanLifetimeProjection.savings[
                            `${index}`
                          ],
                        ).toLocaleString('en-IN')}
                      </TableCell>
                      <TableCell
                        align='right'
                        sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                      >
                        ₹{' '}
                        {Math.round(
                          dataField.data.noLoanLifetimeProjection
                            .cumulativeSavings[`${index}`],
                        ).toLocaleString('en-IN')}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      );
    };

    const FirstYearData = () => {
      const rows = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return (
        <Stack position={'relative'} pt={'10px'}>
          <BarChart
            xAxis={[{ scaleType: 'band', data: dataField !== '' ? rows : [] }]}
            series={[
              {
                data:
                  dataField !== ''
                    ? dataField.data.summaryTable.BillBefore
                    : [],
                label: 'Bills without solar',
                color: '#B8BBBE',
              },
              {
                data:
                  dataField !== '' ? dataField.data.summaryTable.BillAfter : [],
                color: '#7CC674',
                label: 'Bills with solar',
              },
            ]}
            borderRadius={10}
            height={300}
            leftAxis={null}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'top', horizontal: 'right' },
                labelStyle: {
                  fontSize: '13px',
                },
                padding: 0,
                itemMarkWidth: 13,
                itemMarkHeight: 13,
              },
            }}
          />
          <TableContainer
            component={'div'}
            sx={{
              paddingInline: { sm: '16px', xs: '3px' },
            }}
          >
            <Table
              sx={{ minWidth: '100%' }}
              aria-label='simple table'
              size='small'
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Without solar
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    With solar
                  </TableCell>
                  <TableCell
                    align='right'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: { sm: '14px', xs: '13px' },
                    }}
                  >
                    Savings
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                    >
                      {row}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                    >
                      ₹
                      {dataField !== '' &&
                        Math.round(
                          dataField.data.summaryTable.BillBefore[index],
                        ).toLocaleString('en-IN')}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                    >
                      ₹
                      {dataField !== '' &&
                        Math.round(
                          dataField.data.summaryTable.BillAfter[index],
                        ).toLocaleString('en-IN')}
                    </TableCell>
                    <TableCell
                      align='right'
                      sx={{ fontSize: { sm: '14px', xs: '11.5px' } }}
                    >
                      ₹
                      {dataField !== '' &&
                        Math.round(
                          dataField.data.summaryTable.BillBefore[index] -
                            dataField.data.summaryTable.BillAfter[index],
                        ).toLocaleString('en-IN')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      );
    };

    const SummaryChart = () => {
      return (
        <Box
          position={'relative'}
          mt={'40px'}
          sx={{
            paddingInline: { sm: '16px', xs: '4px' },
          }}
        >
          <EnergySavingsLeafRoundedIcon
            sx={{
              fontSize: '410px',
              position: 'absolute',
              color: '#1B833312',
              width: '100%',
              top: '-50px',
              left: '-140px',
            }}
          />
          <Grid container height={'100%'} pt={'80px'}>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Plant size selected
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  textAlign={'right'}
                  fontWeight={'bold'}
                  pl={'5px'}
                >
                  {dataField !== '' && dataField.output.plantSize}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} pt={'6px'}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Annual generation
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  textAlign={'right'}
                  fontWeight={'bold'}
                  pl={'5px'}
                >
                  {dataField !== '' &&
                    dataField.output.solarProducedUnits.toLocaleString(
                      'en-IN',
                    )}{' '}
                  units
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} pt={'6px'}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Lifetime generation
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  textAlign={'right'}
                  fontWeight={'bold'}
                  pl={'5px'}
                >
                  {dataField !== '' &&
                    dataField.output.lifeTimeProductionRounded.toLocaleString(
                      'en-IN',
                    )}{' '}
                  units
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} pt={'32px'}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Cost of solar
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  textAlign={'right'}
                  fontWeight={'bold'}
                  pl={'5px'}
                >
                  ₹ {dataField !== '' && dataField.output.netCostRange}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} pt={'6px'}>
              <Grid item xs={6}>
                <Typography variant='subtitle1' fontWeight={'bold'}>
                  Per unit cost of solar
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  textAlign={'right'}
                  fontWeight={'bold'}
                  pl={'5px'}
                >
                  ₹{' '}
                  {dataField !== '' &&
                    (
                      dataField.output.max_cost /
                      dataField.output.lifeTimeProduction
                    ).toFixed(0)}{' '}
                  per unit
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Box pt={'40px'}>
            <Typography variant='subtitle2' color={'gray'}>
              Estimated based on
              <br />
              {toSentenceCase(propertyDetails.billMonth) || 'January'} bill of
              Rs {propertyDetails.billAmount || 2000} on{' '}
              {propertyDetails.propertyType.toLowerCase() || 'residential'}{' '}
              property in {propertyDetails.address?.state || 'Delhi'}
            </Typography>
          </Box>
        </Box>
      );
    };

    const handleChipClick = (index) => {
      setSelectedChip(index);
    };

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth={'sm'}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            boxShadow: 'none',
            borderRadius: '8px',
            paddingBlock: '16px',
            paddingTop: '10px',
            height: { sm: '500px', xs: '100%' },
          },
        }}
        sx={{
          backdropFilter: 'blur(2px)',
        }}
      >
        <DialogTitle
          component={'div'}
          position={'relative'}
          paddingInline={{ sm: '24px', xs: '10px' }}
          paddingBottom={'0px !important'}
        >
          <Stack
            direction={'row'}
            gap={'10px'}
            pt={'8px'}
            sx={{
              paddingInline: { sm: '16px', xs: '3px' },
            }}
          >
            {dataToShow.map((data, i) => {
              return (
                <Chip
                  label={data.title}
                  icon={data.icon}
                  key={i}
                  color={selectedChip === i ? 'primary' : 'default'} // Primary color if selected
                  onClick={() => handleChipClick(i)} // Toggle selection on click
                  sx={{
                    borderRadius: '6em',
                    backgroundColor:
                      selectedChip === i ? 'primary.main' : 'white',
                    border:
                      selectedChip === i ? '1px solid green' : '1px solid gray',
                    color: selectedChip === i ? 'white' : 'gray',
                    '&:hover': {
                      backgroundColor:
                        selectedChip === i ? 'primary.dark' : 'gray.100',
                    },
                  }}
                />
              );
            })}
          </Stack>
          <Box
            textAlign={'right'}
            top={0}
            right={{ sm: 20, xs: 4 }}
            position={'absolute'}
          >
            <IconButton size='small' onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingInline: { sm: '24px', xs: '10px' },
          }}
        >
          {selectedChip === 0 && <SummaryChart />}
          {selectedChip === 1 && <FirstYearData />}
          {selectedChip === 2 && <LastYearData />}
        </DialogContent>
      </Dialog>
    );
  };

  const DashboardMainContent = () => {
    return (
      <>
        {propertyDetails?.plantSize && !offersLoading && (
          <Box mb={4}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Stack direction={'row'} mt={2} mb={0.5} alignItems={'center'}>
                <Typography variant={'h5'} fontWeight={'bold'}>
                  {propertyDetails?.plantSize}kW{' '}
                  {propertyDetails?.plantType?.toLowerCase()}
                </Typography>
                <IconButton onClick={handlePlantDetailsEditDialogOpen}>
                  <Edit />
                </IconButton>
              </Stack>
              <BenefitsDialog
                open={benefitDialogOpen}
                handleClose={() => setBenefitDialogOpen(false)}
                dataField={dataField}
                propertyDetails={propertyDetails}
              />
              <Box>
                {isActivelyLooking(status) && (
                  <Button
                    sx={{ bgcolor: '#1976D20A', px: 3 }}
                    onClick={() => setBenefitDialogOpen(true)}
                    startIcon={<LeaderboardRoundedIcon />}
                  >
                    Benefits
                  </Button>
                )}
              </Box>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Security
                sx={{
                  color: 'primary.main',
                  fontSize: '42px',
                  marginRight: '2px',
                }}
              />
              <Typography variant='h6'>5 year service warranty</Typography>
            </Stack>
          </Box>
        )}
        <DashboardStepper
          proposals={proposals}
          propertyDetails={propertyDetails}
          vendorData={vendorData}
          recommendations={recommendations}
          userDetails={userDetails}
          district={location.district}
          state={location.state}
        />
        <EditPlantDetailsDialog
          open={isPlantDetailsEditDialogOpen}
          handleClose={handlePlantDetailsEditDialogClose}
          propertyDetails={propertyDetails}
          handleSave={handlePlantDetailsEditDialogSave}
        />
      </>
    );
  };

  return (
    <DashboardLayout
      announcementBar={true}
      propertyDetails={propertyDetails}
      installationTimeline={installationTimeline}
    >
      <Container
        component='main'
        sx={{
          flexGrow: 1,
          pb: 2,
          paddingInline: { md: '24px', xs: '12px' },
          paddingTop: '13px',
        }}
      >
        {!lookingForSolar ? (
          <SolarSearch propertyDetails={propertyDetails} />
        ) : showPlantSizeSelection ? (
          <PlantSize propertyDetails={propertyDetails} />
        ) : (
          DashboardMainContent()
        )}
        <Stack alignItems='flex-start' mt={12}>
          <Typography
            sx={{
              fontSize: {
                xs: theme.typography.h5.fontSize,
                md: theme.typography.h4.fontSize,
              },
              fontWeight: 'bold',
            }}
          >
            All questions answered
          </Typography>
          <Stack
            alignItems={isMobile ? 'flex-start' : 'unset'}
            mt={3}
            flexDirection={isMobile ? 'column' : 'row'}
            width={'100%'}
            gap={isMobile ? '8px' : '25px'}
          >
            {options.map((option, index) => (
              <Card
                key={option.id}
                sx={{
                  cursor: 'pointer',
                  width: '100%',
                  marginTop: 2,
                  borderRadius: '10px',
                  boxShadow: '2px 2px 2px 2px #D3D3D3',
                  maxWidth: '500px',
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flex: 1,
                    height: '100%',
                  }}
                >
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: theme.typography.h5.fontSize,
                          md: theme.typography.h5.fontSize,
                        },
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        lineHeight: '3rem',
                      }}
                      mb={isMobile ? 1 : 2}
                    >
                      <Typography component={'span'}>{option.icon}</Typography>
                      {option.id === 1 ? (
                        <Typography
                          component={'span'}
                          pl={1}
                          fontWeight={'bold'}
                          fontSize={'18px'}
                        >
                          {option.title}
                        </Typography>
                      ) : (
                        <Typography
                          component={'span'}
                          pl={1.5}
                          fontWeight={'bold'}
                          fontSize={'18px'}
                        >
                          {option.title}
                        </Typography>
                      )}
                    </Typography>
                    <Stack
                      flexGrow={1}
                      gap={'24px'}
                      justifyContent={'space-between'}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.secondary.light,
                          fontSize: {
                            xs: theme.typography.h6.fontSize,
                            md: theme.typography.h6.fontSize,
                          },
                          fontWeight: 'bold',
                        }}
                      >
                        {option.name}
                        <br />
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: theme.typography.body2.fontSize,
                            md: theme.typography.body2.fontSize,
                          },
                          color: 'rgba(0, 0, 0, 0.38)',
                        }}
                      >
                        {option.details}
                      </Typography>
                    </Stack>
                    <Typography alignSelf={'flex-end'} py={0.5}>
                      <Link href={option.href} variant='body2' target='_blank'>
                        read more
                      </Link>
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            ))}
          </Stack>
          <Typography
            mt={6}
            sx={{
              fontSize: theme.typography.body2.fontSize,
              color: theme.palette.primary.main,
            }}
          >
            Not sure still?{' '}
            <Link
              onClick={() => {
                window.location.href =
                  'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
              }}
            >
              Here is a guide on how to buy solar
            </Link>
            <br />
            Unsure still?{' '}
            <Link onClick={handleSupportButtonClick}>Talk to an expert</Link>
            <br />
          </Typography>
        </Stack>
      </Container>
    </DashboardLayout>
  );
};
export default DashboardPage;
