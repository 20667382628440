import { WorkspacePremium } from '@mui/icons-material';
import GppGoodIcon from '@mui/icons-material/GppGood';
import {
  Chip,
  Link,
  ListItem,
  ListItemText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import vendor_placeholder from '../../Image/vendor_placeholder.png';
import VendorProfileStatistics from './VendorProfileStatistics';
import VendorSummary from './VendorSummary';

export default function VendorProfile({ vendor }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <ListItem
        alignItems='flex-start'
        sx={{
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >
        <Box>
          <ListItemText
            primary={
              <Typography
                fontWeight='bold'
                fontSize={{ xs: '1.5rem', md: '3rem' }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                gap={1}
              >
                {vendor?.establishmentName}
                {vendor?.verificationStatus === 'VERIFIED' && (
                  <GppGoodIcon
                    sx={{
                      color: 'primary.main',
                      fontSize: { xs: '2rem', md: '3rem' },
                    }}
                  />
                )}
              </Typography>
            }
            secondary={
              <>
                <Typography
                  fontWeight='500'
                  sx={{
                    fontSize: {
                      xs: theme.typography.body2.fontSize,
                      md: theme.typography.body1.fontSize,
                    },
                    color: 'text.secondary',
                  }}
                >
                  {vendor?.address?.locality && (
                    <>
                      {vendor.address.locality}
                      <br />
                    </>
                  )}
                  {vendor?.address?.district && `${vendor.address.district}, `}
                  {vendor?.address?.state && `${vendor.address.state}`}
                  {vendor?.address?.pinCode && ` - ${vendor.address.pinCode}`}
                </Typography>
                {vendor?.suryagharEligible && (
                  <Chip
                    label='PM Suryaghar Empanelled'
                    size={isMobile ? 'small' : ''}
                    sx={{
                      mt: '12px',
                      width: 'fit-content',
                      p: '4px',
                      bgcolor: theme.palette.secondary.lighter,
                    }}
                  />
                )}
              </>
            }
          />
        </Box>
        <Stack alignItems={'center'}>
          {vendor?.logoUrl ? (
            <Box
              display='flex'
              flexDirection='column'
              gap='25px'
              padding={'unset'}
            >
              <Box
                component='img'
                src={vendor?.logoUrl ? vendor?.logoUrl : vendor_placeholder}
                alt='Vendor Logo'
                sx={{ height: { xs: '92px', md: '200px' } }}
              />
            </Box>
          ) : (
            <></>
          )}
          {vendor?.reputation > 70 && (
            <Stack>
              <Stack direction={'row'} alignItems={'center'}>
                <WorkspacePremium
                  sx={{
                    fontSize: { xs: '48px', md: '48px' },
                    color: theme.palette.primary.main,
                  }}
                />
                <Typography variant='h5'>
                  {vendor?.reputation > 100
                    ? 'Highly Reputed'
                    : vendor?.reputation > 70
                      ? 'Well Reputed'
                      : null}
                </Typography>
              </Stack>
              <Link
                mt={1}
                href='https://docs.google.com/document/d/1OhV66KM9SqZVWDVM3rs7qS9Nj0koFl6Y0UMZSiTPvX4/edit?usp=sharing'
                target='_blank'
              >
                How reputation <br />
                score works?
              </Link>
            </Stack>
          )}
        </Stack>
      </ListItem>
      <VendorProfileStatistics vendor={vendor} />
      <VendorSummary vendor={vendor} />
    </Box>
  );
}
