import { Place, SolarPower } from '@mui/icons-material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import hero1 from '../../Image/hero1.svg';
import Logo from '../Logo';
import { fadeAnimateVariants, slideAnimateVariants } from '../animate/animate';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HelpIcon from '@mui/icons-material/Help';
import MenuIcon from '@mui/icons-material/Menu';

const TopSec = ({ startLink, researchLink }) => {
  const [anchorEl, setAnchorEl] = useState(null); // For Menu anchor
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Open menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close menu
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pincode, setPincode] = React.useState('');
  const [plantSize, setPlantSize] = React.useState('');

  const [errors, setErrors] = React.useState({
    pincode: '',
    plantSize: '',
  });

  const validatePincode = async (pincode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pincode}`,
      );
      const data = await response.json();

      if (data[0].Status === 'Success' && data[0].PostOffice?.length > 0) {
        const postOffice = data[0].PostOffice[0];
        return {
          isValid: true,
          error: '',
          district: postOffice.District,
          state: postOffice.State,
        };
      } else {
        return {
          isValid: false,
          error: 'Invalid pincode',
          district: '',
          state: '',
        };
      }
    } catch (error) {
      return {
        isValid: false,
        error: 'Error validating pincode',
        district: '',
        state: '',
      };
    }
  };

  const validateForm = async () => {
    let isValid = true;
    const newErrors = { pincode: '', plantSize: '' };
    let locationData = { district: '', state: '' };

    if (!pincode) {
      newErrors.pincode = 'PinCode is required';
      isValid = false;
    } else if (pincode.length < 6) {
      newErrors.pincode = 'PinCode must be 6 digits';
      isValid = false;
    } else {
      // Only validate pincode with API if length is correct
      const validation = await validatePincode(pincode);
      if (!validation.isValid) {
        newErrors.pincode = validation.error;
        isValid = false;
      } else {
        locationData = {
          district: validation.district,
          state: validation.state,
        };
      }
    }

    if (!plantSize) {
      newErrors.plantSize = 'Plant size is required';
      isValid = false;
    } else if (plantSize < 1 || plantSize > 99) {
      newErrors.plantSize = 'Plant size must be between 1 and 99 kW';
      isValid = false;
    }

    setErrors(newErrors);
    return { isValid, locationData };
  };

  const handleSubmit = async () => {
    const { isValid, locationData } = await validateForm();
    if (isValid) {
      navigate(
        `/open-offers?pincode=${pincode}&plantSize=${plantSize}&district=${encodeURIComponent(locationData.district)}&state=${encodeURIComponent(locationData.state)}`,
      );
    }
  };

  return (
    <Stack
      sx={{
        minHeight: '90vh',
      }}
    >
      <Stack
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Logo height={'80px'} width={'156px'} />
        <Stack spacing='15px' direction='row' padding={'10px 0px'} gap={'10px'}>
          <Stack
            display={isMobile ? 'none' : 'flex'}
            direction={'row'}
            gap={'10px'}
          >
            <Button
              onClick={() => {
                if (researchLink) {
                  navigate(researchLink);
                } else {
                  window.location.href =
                    'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
                }
              }}
              size='small'
              variant='text'
              component={motion.button}
              variants={slideAnimateVariants}
              initial='initial'
              whileInView='animate'
              custom={2.5}
              viewport={{
                once: true,
              }}
              sx={{
                borderRadius: '50px',
                padding: '0px 20px',
              }}
            >
              Research
            </Button>
            <Button
              variant='outlined'
              size='small'
              component={motion.button}
              variants={slideAnimateVariants}
              initial='initial'
              whileInView='animate'
              viewport={{
                once: true,
              }}
              disableElevation
              onClick={() => {
                window.location.href =
                  'https://support.vaysolar.com/hc/en-us/requests/new?ticket_form_id=14570268863260';
              }}
              style={{
                borderRadius: '50px',
                padding: '0px 20px',
              }}
            >
              Book Expert call
            </Button>
          </Stack>

          <Button
            size='small'
            variant='filled'
            component={motion.button}
            variants={slideAnimateVariants}
            initial='initial'
            whileInView='animate'
            onClick={() => navigate('/login')}
            style={{
              borderRadius: '50px',
              padding: '0px 20px',
              marginLeft: '0px',
              color: 'green',
              backgroundColor: theme.palette.primary.lighter,
            }}
          >
            Sign In
          </Button>

          <Box
            style={{ marginLeft: '0px' }}
            sx={{ display: isMobile ? 'flex' : 'none' }}
          >
            <IconButton
              onClick={handleMenuOpen}
              sx={{
                color: 'black',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  if (researchLink) {
                    navigate(researchLink);
                  } else {
                    window.location.href =
                      'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
                  }
                }}
              >
                Research
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleMenuClose();
                  window.location.href =
                    'https://support.vaysolar.com/hc/en-us/requests/new?ticket_form_id=14570268863260';
                }}
              >
                Book Expert Call
              </MenuItem>
            </Menu>

            {!isMobile && (
              <div style={{ display: 'flex', gap: '10px' }}>
                <Button
                  onClick={() => {
                    if (researchLink) {
                      navigate(researchLink);
                    } else {
                      window.location.href =
                        'https://www.vaysolar.com/docs/?utm_source=landing-page&utm_medium=landing-page&utm_campaign=landing-page';
                    }
                  }}
                  size='small'
                  variant='text'
                  component={motion.button}
                  variants={slideAnimateVariants}
                  initial='initial'
                  animate='animate'
                  sx={{
                    borderRadius: '50px',
                    padding: '0px 20px',
                  }}
                >
                  Research
                </Button>
                <Button
                  variant='outlined'
                  size='small'
                  component={motion.button}
                  variants={slideAnimateVariants}
                  initial='initial'
                  animate='animate'
                  disableElevation
                  onClick={() => {
                    window.location.href =
                      'https://support.vaysolar.com/hc/en-us/requests/new?ticket_form_id=14570268863260';
                  }}
                  sx={{
                    borderRadius: '50px',
                    padding: '0px 20px',
                  }}
                >
                  Book Expert Call
                </Button>
              </div>
            )}
          </Box>
        </Stack>
      </Stack>
      <Grid
        pt={{ sm: '32px', xs: '10px' }}
        pb={'32px'}
        container
        spacing={'28px'}
      >
        <Grid
          container
          item
          xs={12}
          direction={'row-reverse'}
          sx={{
            spacing: { sm: '10px', xs: '40px' },
          }}
          pb={'76px'}
        >
          <Grid
            display={isMobile ? 'none' : 'flex'}
            item
            sm={5}
            xs={12}
            sx={{
              position: 'relative',
              pt: { xs: '0px', sm: '193px' },
            }}
          >
            <Box
              className='center green pulse'
              variants={fadeAnimateVariants}
              initial='initial'
              custom={2.5}
              whileInView='animate'
              viewport={{
                once: true,
              }}
              mt={'3rem'}
              component={motion.div}
              sx={{
                width: isMobile ? '62px' : '96px',
                height: isMobile ? '62px' : '96px',
                borderRadius: '50%',
                boxShadow: '0px 0px 1px 1px #FBD300',
                backgroundColor: '#FBD300',
                animation: 'pulse-animation 2s infinite',
                position: 'absolute',
                left: '10px',
                top: 0,
                zIndex: 1,
                '@keyframes pulse-animation': {
                  '0%': {
                    boxShadow: '0 0 0 0px #FBD300',
                  },
                  '100%': {
                    boxShadow: '0 0 0 12px rgba(0, 0, 0, 0)',
                  },
                },
              }}
            >
              {/* Your component content */}
            </Box>
            <Box
              component={motion.img}
              variants={fadeAnimateVariants}
              initial='initial'
              whileInView='animate'
              sx={{
                transform: { sm: 'scaleX(1)', xs: 'scaleX(-1)' },
              }}
              custom={1.5}
              viewport={{
                once: true,
              }}
              width='100%'
              // maxWidth={{
              //   md: 380,
              //   sm: 350,
              //   xs: 350,
              // }}
              height='100%'
              src={hero1}
              alt=''
            />
          </Grid>
          <Grid item sm={7} xs={12} alignSelf={'flex-end'}>
            <Grid item xs={12}>
              <Typography
                custom={0.5}
                variant='h1'
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                viewport={{
                  once: true,
                }}
                mb={isMobile ? 'none' : '8rem'}
                component={motion.h2}
                sx={{ fontWeight: '900' }}
                // fontWeight={theme.typography.h4.fontWeight}
                fontSize={{
                  md: '48px',
                  sm: '40px',
                  xs: '34px',
                }}
              >
                Going{' '}
                <Box
                  component='span'
                  sx={{
                    color: 'primary.main',
                  }}
                >
                  solar
                </Box>{' '}
                was never this easy!
                <Typography fontWeight={'bold'} mt={'8px'}>
                  Find. Select. Finance. Install. Maintain.
                </Typography>
              </Typography>
            </Grid>
            <Grid
              display={isMobile ? 'flex' : 'none'}
              item
              sm={5}
              xs={12}
              sx={{
                position: 'relative',
                pt: { xs: '0px', sm: '25px' },
              }}
            >
              <Box
                className='center green pulse'
                variants={fadeAnimateVariants}
                initial='initial'
                custom={2.5}
                whileInView='animate'
                viewport={{
                  once: true,
                }}
                mt={'3rem'}
                component={motion.div}
                sx={{
                  width: isMobile ? '62px' : '96px',
                  height: isMobile ? '62px' : '96px',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 1px 1px #FBD300',
                  backgroundColor: '#FBD300',
                  animation: 'pulse-animation 2s infinite',
                  position: 'absolute',
                  left: isMobile ? '20px' : '200px',
                  top: 0,
                  zIndex: 1,
                  '@keyframes pulse-animation': {
                    '0%': {
                      boxShadow: '0 0 0 0px #FBD300',
                    },
                    '100%': {
                      boxShadow: '0 0 0 12px rgba(0, 0, 0, 0)',
                    },
                  },
                }}
              >
                {/* Your component content */}
              </Box>
              <Box
                component={motion.img}
                variants={fadeAnimateVariants}
                initial='initial'
                whileInView='animate'
                sx={{
                  transform: { sm: 'scaleX(1)', xs: 'scaleX(-1)' },
                }}
                custom={2.5}
                viewport={{
                  once: true,
                }}
                width='100%'
                maxWidth={{
                  md: 380,
                  sm: 350,
                  xs: 350,
                }}
                height='100%'
                src={hero1}
                alt=''
              />
            </Grid>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: '16px',
                padding: '24px',
                backgroundColor: 'white',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                width: { xs: '100%', sm: '456px' }, // Increased width for desktop
                margin: '20px 0',
              }}
            >
              <Typography variant='h5' fontWeight={'bold'}>
                Top <span style={{ color: 'green' }}>rated & best</span> <br />
                solar professionals!
              </Typography>
              <Stack spacing={3} sx={{ mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <Place sx={{ color: 'green', mr: 1, my: 0.5 }} />
                  <TextField
                    id='pin-code'
                    label='Pin code'
                    variant='standard'
                    value={pincode}
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    inputProps={{
                      maxLength: 6,
                      pattern: '[0-9]*',
                    }}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      if (value.length <= 6) {
                        setPincode(value);
                        setErrors((prev) => ({ ...prev, pincode: '' }));
                      }
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <SolarPower sx={{ color: 'green', mr: 1, my: 0.5 }} />
                  <TextField
                    id='plant-size'
                    label='Plant size (kW)'
                    variant='standard'
                    value={plantSize}
                    error={!!errors.plantSize}
                    helperText={errors.plantSize}
                    onChange={(e) => {
                      setPlantSize(e.target.value);
                      setErrors((prev) => ({ ...prev, plantSize: '' }));
                    }}
                    sx={{ width: '100%' }}
                  />
                </Box>

                <Stack direction={{ xs: 'column', sm: 'row' }} gap={1}>
                  <Button
                    variant='contained'
                    startIcon={<LocalOfferIcon />}
                    onClick={handleSubmit}
                  >
                    See Local Offers
                  </Button>
                  <Button
                    variant='outlined'
                    startIcon={<HelpIcon />}
                    onClick={() => navigate('/start')}
                  >
                    Help me choose
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TopSec;
