import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getVendorTopSitePhotoDetails } from '../../services/CustomerService';
import ImagePreviewModal from '../vendor-details/ImagePreviewModel';

export default function ImageListWithTitle() {
  const [sitePhotos, setSitePhotos] = React.useState([]); // Initialize as an empty array
  const [imgArray, setImgArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = (currentPhoto) => {
    const currentPhotoUrl = currentPhoto.url; // Get the URL of the current photo
    const remainingUrls = sitePhotos
      .filter((photo) => photo.url !== currentPhotoUrl) // Exclude the current photo from the remaining URLs
      .map((photo) => photo.url); // Map to just the URLs

    const urls = [currentPhotoUrl, ...remainingUrls]; // Create an array with the current photo first, followed by the remaining URLs
    setImgArray(urls); // Set the array of URLs
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setImgArray([]);
    setOpenModal(false);
  };

  useEffect(() => {
    const onMount = async () => {
      try {
        const sitePhotoData = await getVendorTopSitePhotoDetails();
        setSitePhotos(sitePhotoData);
      } catch (error) {
        console.error(error);
      }
    };
    onMount();
  }, []);

  return (
    <>
      <Box>
        <ImagePreviewModal
          open={openModal}
          onClose={handleModalClose}
          imageSrcArray={imgArray}
        />
      </Box>

      <ImageList
        sx={{
          gap: '10px !important',
          width: '100%',
          borderRadius: '10px',
          paddingTop: '20px',
          paddingRight: '10px',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr) !important',
            sm: 'repeat(3, 1fr) !important',
            md: 'repeat(3, 1fr) !important',
            lg: 'repeat(3, 1fr) !important',
          },
          height: 'auto',
        }}
      >
        {Array.isArray(sitePhotos) && sitePhotos.length > 0 ? ( // Check if sitePhotos is an array and has items
          sitePhotos.map((item) => (
            <ImageListItem
              key={item.url}
              sx={{ borderRadius: '10px', height: '200px' }}
            >
              <img
                src={item.url}
                style={{
                  borderRadius: '10px',
                  height: '200px',
                  cursor: 'pointer',
                }}
                alt={item.displayName}
                loading='lazy'
                onClick={() => handleModalOpen(item)}
              />
              <ImageListItemBar
                position='below'
                sx={{
                  paddingRight: '10px',
                  borderRadius: '10px',
                  '& .MuiImageListItemBar-subtitle': {
                    lineHeight: '2', // Adjust as needed
                  },
                }}
                title={
                  item.establishmentName
                    ? item.displayName + ', ' + item.location
                    : item.location
                }
                subtitle={
                  item.establishmentName
                    ? item.establishmentName
                    : item.displayName
                }
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.title}`}
                  >
                    <CheckCircleIcon fontSize='medium' color='primary' />
                  </IconButton>
                }
                subtitleTypographyProps={{
                  sx: {
                    lineHeight: '1.5', // Adjust the line height as needed
                  },
                }}
              />
            </ImageListItem>
          ))
        ) : (
          <></> // Optional: Render a placeholder or nothing if there are no photos
        )}
      </ImageList>
    </>
  );
}
