import { Engineering } from '@mui/icons-material';
import { Box, CircularProgress, Fab, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import OffersNearYou from '../components/dashboard/OffersNearYou';
import VendorAccordion from '../components/dashboard/VendorAccordion';
import JourneyLayout from '../layout/JourneyLayout';
import { getOpenOffers } from '../services/CustomerService';
import { trackGoogleAnalyticsEvent } from '../utils/utils';

export default function OpenOffersPage() {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const pincode = searchParams.get('pincode');
  const plantSize = searchParams.get('plantSize');
  const district = decodeURIComponent(searchParams.get('district'));
  const state = decodeURIComponent(searchParams.get('state'));
  const [recommendations, setRecommendations] = useState([]);
  const [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setIsLoading(true);
        const recommendationsResponse = await getOpenOffers(pincode, plantSize);
        const recommendations = recommendationsResponse
          .filter((recommendation) => recommendation.proposalConfig)
          .map((recommendation) => ({
            ...recommendation,
            vendor: {
              ...recommendation.vendor,
              recommendationId: recommendation.id,
            },
          }));

        const vendorList = recommendationsResponse
          .filter((recommendation) => !recommendation.proposalConfig)
          .map((recommendation) => ({
            ...recommendation.vendor,
            recommendationId: recommendation.id,
          }));

        setRecommendations(recommendations);
        setVendorData(vendorList);
        trackGoogleAnalyticsEvent('RECOMMENDATIONS', 'Loaded Recommendations', {
          offersCount: recommendations.length,
          vendorsCount: vendorList.length,
          district: district,
          state: state,
          plantSize: plantSize,
        });
      } catch (error) {
        console.error('Error fetching offers:', error);
        setRecommendations([]);
        setVendorData([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (pincode && plantSize) {
      fetchOffers();
    }
  }, [pincode, plantSize]);

  const handleSupportButtonClick = () => {
    window.open('https://forms.gle/FYBfYapmfg77pZkz9', '_blank');
  };

  return (
    <JourneyLayout>
      <Box sx={{ maxWidth: '1048px', mx: 'auto' }}>
        <Fab
          sx={{
            position: 'fixed',
            bottom: { md: 24, xs: 72 },
            right: { md: 96, xs: 16 },
          }}
          color='primary'
          onClick={handleSupportButtonClick}
          variant='extended'
        >
          <Engineering sx={{ mr: 1 }} />
          Expert Call
        </Fab>
      </Box>
      <Box sx={{ mx: { sm: 4, xs: 2 } }}>
        <Stack spacing={2}>
          <Box
            sx={{
              maxWidth: { sm: 900, xs: 400 },
            }}
          >
            {isLoading ? (
              <Box display='flex' justifyContent='center' mt={4}>
                <CircularProgress />
              </Box>
            ) : (
              <Stack spacing={2} mt={2}>
                {recommendations.length > 0 && (
                  <Box>
                    <Typography
                      fontSize={theme.typography.h5.fontSize}
                      fontWeight={'bold'}
                      mb={1.5}
                    >
                      {recommendations.length} top rated offers in {district},{' '}
                      {state}
                    </Typography>

                    <OffersNearYou
                      recommendations={recommendations}
                      district={district}
                      state={state}
                    />
                  </Box>
                )}

                <Box>
                  <Typography
                    fontSize={theme.typography.h5.fontSize}
                    fontWeight={'bold'}
                    mt={2}
                  >
                    Top {vendorData.length} installers
                  </Typography>
                  <VendorAccordion vendorData={vendorData} />
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    </JourneyLayout>
  );
}
