import { AccountCircle, CheckCircleOutline } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  addCustomerDetails,
  checkPhoneNumber,
  createMatch,
  getTokens,
} from '../../services/CustomerService';
import { useSearchAnimate } from '../../store/SearchAnimateStore';
import { PropertyTypeEnum } from '../../utils/app.enums';
import { months } from '../constant';

export default function LoginMiniForm({
  open,
  handleClose,
  userDetails,
  vendorId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { setIsAlert } = useSearchAnimate();
  const [searchParams] = useSearchParams();
  const pincode = searchParams.get('pincode');
  const plantSize = searchParams.get('plantSize');

  const [submitting, setSubmitting] = useState(false);
  const [optIn, setOptIn] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);

  const handleOptInChange = (event) => {
    setOptIn(event.target.checked);
  };

  const removeCountryCode = (phoneNumber) => {
    if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
      return phoneNumber?.slice(2);
    }
    return phoneNumber;
  };

  const [formValues, setFormValues] = useState({
    contactPerson: '',
    phoneNumber: '',
  });

  const [formErrors, setFormErrors] = useState({
    contactPerson: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (userDetails) {
      setFormValues((prevValues) => ({
        ...prevValues,
        contactPerson: userDetails.name || '',
        phoneNumber: removeCountryCode(userDetails.phoneNumber) || '',
      }));
    }
  }, [userDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSignInClick = () => {
    navigate('/login');
  };

  const validateForm = () => {
    const errors = {};

    if (!formValues.contactPerson) {
      errors.contactPerson = 'Name is required';
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Phone number must be 10 digits';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setSubmitting(true);

    try {
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      if (!userDetails) {
        let registeredUserDetails = null;
        const userAlreadyExists = await checkPhoneNumber(formJson.phoneNumber);
        if (userAlreadyExists.exists) {
          registeredUserDetails = userAlreadyExists.user;
          setShowLogin(true);
        } else {
          const currDate = new Date();
          const newUserRequestParams = {
            fullName: formJson.contactPerson,
            phoneNumber: formJson.phoneNumber,
            propertyDetails: {
              propertyType: PropertyTypeEnum.RESIDENTIAL,
              pinCode: pincode,
              plantSize: plantSize,
              shadowFreeArea: 1000,
              billAmount: 1000,
              billMonth:
                months[
                  new Date(
                    currDate.setMonth(currDate.getMonth() - 1),
                  ).getMonth()
                ],
            },
          };
          const newUser = await addCustomerDetails(newUserRequestParams);
          registeredUserDetails = newUser;

          setIsAlert({ show: true, msg: 'Account created successfully' });
          const tokens = await getTokens(registeredUserDetails);
          if (tokens) {
            localStorage.setItem('accessToken', tokens.accessToken);
            localStorage.setItem('refreshToken', tokens.refreshToken);
          }
          await createMatch(registeredUserDetails?.id, vendorId);
          setAccountCreated(true);
          setTimeout(() => {
            handleClose(true);
          }, 3000);
        }
      }
    } catch (error) {
      error.handled = true;
      setIsAlert({
        show: true,
        msg: 'Failed to create account. Please try again later.',
      });
      return;
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setShowLogin(false);
  }, [formValues.phoneNumber]);

  const MainContent = () => {
    return (
      <>
        <DialogTitle
          display={'grid'}
          gap={'10px'}
          minWidth={'600px'}
          borderRadius={'10px'}
          sx={{ paddingBottom: 'unset' }}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={theme.typography.h5.fontSize}
            display={'flex'}
            mb={'24px'}
          >
            <AccountCircle fontSize='large' sx={{ mr: '18px' }} />
            Add contact details
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <TextField
            id='contactperson'
            name='contactPerson'
            label='Full Name'
            type='text'
            variant='outlined'
            size='small'
            required
            slotProps={{ inputLabel: { shrink: true } }}
            margin='dense'
            value={formValues.contactPerson}
            onChange={handleInputChange}
            error={!!formErrors.contactPerson}
            helperText={formErrors.contactPerson}
          />
          <TextField
            required
            name='phoneNumber'
            type='tel'
            inputMode='numeric'
            size='small'
            placeholder='__________'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+91</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
              style: { textAlign: 'left', letterSpacing: '0.1rem' },
            }}
            margin='dense'
            value={formValues.phoneNumber}
            onChange={handleInputChange}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
            variant='outlined'
          />
          {showLogin && (
            <Typography
              variant='body2'
              color='error'
              sx={{ marginTop: '1rem' }}
            >
              Phone number already exists.
            </Typography>
          )}
          {showLogin && (
            <Button
              variant='outlined'
              sx={{
                alignItems: 'flex-start',
                textTransform: 'none',
                width: 'fit-content',
                fontSize: theme.typography.body1.fontSize,
                mt: 1,
              }}
              onClick={handleSignInClick}
            >
              Sign in to shortlist
            </Button>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={optIn}
                onChange={handleOptInChange}
                color='primary'
              />
            }
            label={
              <Typography variant='body2' sx={{ fontSize: '0.75rem', mt: 4 }}>
                By providing your contact details, you consent to receive
                calls/whatsapp messages from VaySolar regarding your solar
                offers.
              </Typography>
            }
          />
          <DialogActions
            sx={{
              padding: '30px 0px',
              justifyContent: 'right',
              gap: '15px',
              display: isMobile ? 'flex' : 'none',
            }}
          >
            <Button
              variant='outlined'
              onClick={() => handleClose(false)}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              startIcon={
                submitting ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  <CheckIcon />
                )
              }
              disabled={submitting || showLogin}
            >
              {submitting ? 'Creating...' : 'Create'}{' '}
            </Button>
          </DialogActions>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '24px',
            justifyContent: 'right',
            gap: '15px',
            display: isMobile ? 'none' : 'flex',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => handleClose(false)}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            type='submit'
            startIcon={
              submitting ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
              ) : (
                <CheckIcon />
              )
            }
            disabled={submitting}
          >
            {submitting ? 'Creating...' : 'Create'}{' '}
          </Button>
        </DialogActions>
      </>
    );
  };

  const LoginSuccessRedirectionContent = () => {
    return (
      <>
        <DialogTitle
          display={'grid'}
          gap={'10px'}
          minWidth={'600px'}
          borderRadius={'10px'}
          sx={{ paddingBottom: 'unset' }}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={theme.typography.h5.fontSize}
            display={'flex'}
            mb={'24px'}
          >
            <AccountCircle fontSize='large' sx={{ mr: '18px' }} />
            Add contact details
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Stack gap={2} alignItems={'center'} mt={4}>
            <CheckCircleOutline
              sx={{ fontSize: '12rem', color: theme.palette.secondary.light }}
            />
            <Typography variant='h5'>
              Your account has been created successfully.
            </Typography>
            <Typography>
              You will now be redirected to Dashboard where you can see your
              personalized offers, vendors and more.
            </Typography>
          </Stack>
        </DialogContent>
      </>
    );
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        sx={{
          borderRadius: '10px',
          '& .MuiDialog-paper': { borderRadius: '10px' },
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
          elevation: '2',
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(0.4px)',
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
            },
          },
        }}
      >
        {accountCreated ? LoginSuccessRedirectionContent() : MainContent()}
      </Dialog>
    </>
  );
}
