import { Box, CircularProgress, Container, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VendorBusinessDocuments from '../components/vendor-details/BusinessDocuments';
import CustomerSitePhotoCard from '../components/vendor-details/CustomerSitePhotoCard';
import VendorProfile from '../components/vendor-details/VendorProfile';
import VendorProfileLayout from '../layoutV2/VendorProfileLayout';
import { getVendorDetails } from '../services/CustomerService';
import { usePageTitleStore } from '../store/PageTitleStore';
import { calculateYears, formatDate } from '../utils/utils';

const VendorProfilePage = () => {
  const { vendorInfo } = useParams();
  const [establishmentName, vendorId] = vendorInfo.split('_');
  const { updateTitle, updateDescription, updateImage } = usePageTitleStore();
  const [isLoading, setIsLoading] = useState(false);
  const [vendorData, setVendorData] = useState(null);

  useEffect(() => {
    updateTitle(establishmentName);
    updateImage(vendorData?.logoUrl);
  }, [establishmentName, updateImage, updateTitle, vendorData?.logoUrl]);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);
      try {
        const vendorData = await getVendorDetails(btoa(vendorId));
        setVendorData(vendorData);
        const description = `${vendorData.establishmentName} • Estd on ${formatDate(vendorData.establishmentDate)} • ${vendorData.experienceInMW} MW+ exp in ${calculateYears(vendorData.establishmentDate)} years`;
        updateDescription(description);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    onMount();
  }, []);

  const vendorProfilePageContent = () => {
    return isLoading ? (
      <CircularProgress sx={{ alignSelf: 'center' }} />
    ) : (
      <>
        <Container px={2} py={2}>
          <Box mb={20} mt={2} sx={{ padding: { xs: '0px', md: '0px 24px' } }}>
            <VendorProfile vendor={vendorData} />
            <Divider sx={{ p: 1, mb: 2 }} />
            <CustomerSitePhotoCard initialSitePhotos={vendorData} />
            <Divider sx={{ p: 1, mb: 2 }} />
            <VendorBusinessDocuments vendor={vendorData} />
          </Box>
        </Container>
      </>
    );
  };

  return (
    <VendorProfileLayout vendorData={vendorData}>
      {vendorProfilePageContent()}
    </VendorProfileLayout>
  );
};
export default VendorProfilePage;
