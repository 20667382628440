import CloseIcon from '@mui/icons-material/Close';
import EnergySavingsLeafRoundedIcon from '@mui/icons-material/EnergySavingsLeafRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { ChartsReferenceLine } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateInstallationTimeline } from '../../services/CustomerService';
import { useSearchAnimate } from '../../store/SearchAnimateStore';
import { InstallationTimeline } from '../../utils/app.enums';
import { toSentenceCase } from '../../utils/utils';
import PropertyDetails from '../ui/PropertyDetails';

const isActivelyLooking = (installationTimeline) => {
  const timelines = ['1 month', '1-3 months', '3+ months'];
  return timelines.includes(installationTimeline);
};

const InstallationTimelineDialog = ({
  open,
  handleClose,
  handleUpdateStatus,
  status,
}) => {
  const { t } = useTranslation();
  const [additionalComments, setAdditionalComments] = useState('');
  const [selectedRadioOption, setSelectedRadioOption] = useState('');

  const installationTimelineOptions = [
    {
      name: t('main.installationTimeline.options.opt1'),
      value: InstallationTimeline.ONE_MONTH,
    },
    {
      name: t('main.installationTimeline.options.opt2'),
      value: InstallationTimeline.ONE_THREE_MONTHS,
    },
    {
      name: t('main.installationTimeline.options.opt3'),
      value: InstallationTimeline.THREE_PLUS_MONTHS,
    },
  ];
  const feedbackOptions = [
    {
      name: 'Budget Issue',
      value: 'Budget issue',
    },
    {
      name: 'Price too high',
      value: 'Price too high',
    },
    {
      name: 'Installed already elsewhere',
      value: 'Installed already elsewhere',
    },
    {
      name: 'Electricity connection issues',
      value: 'Electricity connection issues',
    },
    {
      name: "Didn't find an installer",
      value: "Didn't find an installer",
    },
    {
      name: 'Other',
      value: 'Other',
    },
  ];

  const options = isActivelyLooking(status)
    ? feedbackOptions
    : installationTimelineOptions;

  const handleDialogUpdateStatus = async () => {
    if (selectedRadioOption === 'Other' && additionalComments === '') {
      alert('Input cannot be empty!');
      return;
    }
    let newStatus;
    let newFeedback;
    if (isActivelyLooking(status)) {
      newStatus = InstallationTimeline.NOT_LOOKING;
      newFeedback = selectedRadioOption;
    } else {
      newStatus = selectedRadioOption;
    }
    await handleUpdateStatus(newStatus, newFeedback, additionalComments);
    setAdditionalComments('');
  };

  useEffect(() => {
    setSelectedRadioOption(options[0].value);
  }, [status]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          borderRadius: '8px',
          paddingBlock: '16px',
          paddingInline: { sm: '16px', xs: '3px' },
        },
      }}
      sx={{
        backdropFilter: 'blur(2px)',
      }}
    >
      <DialogTitle component={'div'}>
        <Typography variant='h6' fontWeight={'bold'}>
          {isActivelyLooking(status)
            ? 'Not looking for solar anymore'
            : 'Update Installation Timeline'}
        </Typography>
        <Typography
          variant='body2'
          pt='3px'
          sx={{
            color: 'text.secondary',
          }}
        >
          {isActivelyLooking(status)
            ? 'Please choose reason for not installing?'
            : 'When do you want to install?'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          name='selectedOption'
          value={selectedRadioOption}
          onChange={(e) => setSelectedRadioOption(e.target.value)}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={
                <Radio
                  size='small'
                  sx={{
                    paddingBlock: '6px',
                  }}
                />
              }
              label={<Typography variant='body2'>{option.name}</Typography>}
            />
          ))}
        </RadioGroup>
        {isActivelyLooking(status) && (
          <Collapse
            in={selectedRadioOption === 'Other'}
            sx={{
              mt: 1,
            }}
            unmountOnExit
            collapsedSize={0}
          >
            <TextField
              multiline
              rows={3}
              fullWidth
              variant='outlined'
              placeholder='Add additional comments'
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
            />
          </Collapse>
        )}
        <Stack
          direction={'row'}
          pt={'22px'}
          justifyContent={'flex-end'}
          spacing={1}
        >
          <Button onClick={handleClose} size='small'>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleDialogUpdateStatus}
            size='small'
            endIcon={<UpgradeIcon />}
            disableElevation
          >
            Update
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default function InstallationStatus({
  propertyDetails,
  installationTimeline = '1 month',
}) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(installationTimeline);
  const [benefitDialogOpen, setBenefitDialogOpen] = useState(false);
  const { setIsLooking } = useSearchAnimate();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setStatus(installationTimeline);
  }, [installationTimeline]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = async (
    newStatus,
    newFeedback,
    additionalComments,
  ) => {
    setIsLoading(true);
    try {
      handleClose();
      const updatedStatus = await updateInstallationTimeline(
        propertyDetails,
        newStatus,
        newFeedback,
        additionalComments,
      );
      setStatus(updatedStatus.installationTimeline);
      if (
        updatedStatus.installationTimeline === InstallationTimeline.NOT_LOOKING
      ) {
        setIsLooking(false);
      } else {
        setIsLooking(true);
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <InstallationTimelineDialog
        open={open}
        handleClose={handleClose}
        handleUpdateStatus={handleUpdateStatus}
        status={status}
        setStatus={setStatus}
      />
      <Box
        sx={{
          maxWidth: '1048px',
          mx: 'auto',
          background: isActivelyLooking(status)
            ? theme.palette.secondary.lighter
            : theme.palette.grayLight,
          borderBottomRightRadius: '12px',
          borderBottomLeftRadius: '12px',
          paddingBottom: '8px',
        }}
      >
        <Stack flex={1} paddingInline={'12px'} paddingBlock={'10px'}>
          <Stack
            width={'100%'}
            direction={'row'}
            // alignItems={{ sm: 'center', xs: 'baseline' }}
            alignItems={'center'}
            columnGap={'10px'}
          >
            <Box
              sx={{
                backgroundColor: isActivelyLooking(status)
                  ? theme.palette.warning.secondary
                  : theme.palette.gray,
                animation:
                  isActivelyLooking(status) && 'pulse-animation 2s infinite',
                '@keyframes pulse-animation': {
                  '0%': {
                    boxShadow: `0 0 0 0px ${theme.palette.warning.secondary}`,
                  },
                  '100%': {
                    boxShadow: '0 0 0 12px rgba(0, 0, 0, 0)',
                  },
                },
              }}
              width={'16px'}
              height={'16px'}
              borderRadius={'50%'}
            />
            <Typography variant='body1' color={'gray'}>
              {isActivelyLooking(status) ? (
                <>
                  <b>Actively</b> looking.
                </>
              ) : (
                <>
                  <b>Not</b> looking for solar right now.
                </>
              )}{' '}
              <Link
                onClick={handleClickOpen}
                component={'span'}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <b>Update</b> status
              </Link>
            </Typography>
          </Stack>
          <Accordion
            sx={{
              width: '100%',
              borderRadius: '9px !important',
              boxShadow: 'none',
              backgroundColor: 'transparent',
              '&:before': {
                display: 'none',
              },
              marginTop: '0px !important',
            }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1-content'
              id='panel1-header'
              sx={{
                paddingInline: { sm: '12px', xs: '8px' },
                '& .MuiAccordionSummary-content': {
                  marginBlock: '16px',
                },
              }}
            >
              <Typography variant='subtitle2' fontWeight={'bold'}>
                {' '}
                {propertyDetails?.sanctionedLoad} kW load
                {propertyDetails?.siteVisitData?.roofTopArea &&
                  `, ${propertyDetails.siteVisitData.roofTopArea} sq ft`}
                {propertyDetails?.siteVisitData?.region &&
                  ` @ ${propertyDetails.siteVisitData.region}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                paddingInline: { sm: '12px', xs: '8px' },
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                pt={'4px'}
              >
                <PropertyDetails
                  textVariant='primary'
                  overrideAlignment='start'
                  propertyDetails={propertyDetails}
                />
                <Typography variant='subtitle2' ml={'auto'} textAlign={'start'}>
                  50% complete
                </Typography>
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Stack
            direction='row'
            paddingInline={'12px'}
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'14px'}
          >
            {!expanded && (
              <Typography variant='subtitle2' textAlign={'start'}>
                50% complete
              </Typography>
            )}
          </Stack>
          {isLoading && <CircularProgress size={24} />}
        </Stack>
      </Box>
    </>
  );
}
