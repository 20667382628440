import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EngineeringIcon from '@mui/icons-material/Engineering';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  addCustomerDetails,
  checkPhoneNumber,
  createMatch,
  createVisit,
  createVisitOpen,
  getTokens,
  updateIsConvertedStatus,
} from '../../services/CustomerService';
import { sendSiteVisitInternalNotif } from '../../services/EventService';
import { useSearchAnimate } from '../../store/SearchAnimateStore';
import { PropertyTypeEnum } from '../../utils/app.enums';
import { months } from '../constant';
import PropertyDetails from './PropertyDetails';

export default function ArrangeVisitDialog({
  open,
  handleClose,
  propertyDetails,
  vendorId,
  userDetails,
  configId,
  recommendationId,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setIsAlert } = useSearchAnimate();
  const [searchParams] = useSearchParams();
  const pincode = searchParams.get('pincode');
  const plantSize = searchParams.get('plantSize');

  const [submitting, setSubmitting] = useState(false);

  const removeCountryCode = (phoneNumber) => {
    if (phoneNumber?.startsWith('91') && phoneNumber?.length === 12) {
      return phoneNumber?.slice(2);
    }
    return phoneNumber;
  };

  const [formValues, setFormValues] = useState({
    fullAddress: '',
    visitDate: '',
    contactPerson: '',
    phoneNumber: '',
  });

  const [formErrors, setFormErrors] = useState({
    fullAddress: '',
    visitDate: '',
    contactPerson: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (userDetails) {
      setFormValues((prevValues) => ({
        ...prevValues,
        contactPerson: userDetails.name || '',
        phoneNumber: removeCountryCode(userDetails.phoneNumber) || '',
      }));
    }
  }, [userDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    const today = new Date().toISOString().split('T')[0];

    if (!formValues.fullAddress) {
      errors.fullAddress = 'Full address is required';
    }

    if (!formValues.visitDate) {
      errors.visitDate = 'Visit date is required';
    } else if (formValues.visitDate < today) {
      errors.visitDate = 'Visit date cannot be in the past';
    }

    if (!formValues.contactPerson) {
      errors.contactPerson = 'Contact person is required';
    }

    if (!formValues.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      errors.phoneNumber = 'Phone number must be 10 digits';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setSubmitting(true);

    try {
      const formData = new FormData(event.currentTarget);
      const formJson = Object.fromEntries(formData.entries());
      //This flow is for unauthenticated users
      if (!userDetails) {
        let registeredUserDetails = null;
        const userAlreadyExists = await checkPhoneNumber(formJson.phoneNumber);
        if (userAlreadyExists.exists) {
          registeredUserDetails = userAlreadyExists.user;
        } else {
          const currDate = new Date();
          const newUserRequestParams = {
            fullName: formJson.contactPerson,
            phoneNumber: formJson.phoneNumber,
            propertyDetails: {
              propertyType: PropertyTypeEnum.RESIDENTIAL,
              pinCode: pincode,
              plantSize: plantSize,
              shadowFreeArea: 1000,
              billAmount: 1000,
              billMonth:
                months[
                  new Date(
                    currDate.setMonth(currDate.getMonth() - 1),
                  ).getMonth()
                ],
            },
          };
          const newUser = await addCustomerDetails(newUserRequestParams);
          registeredUserDetails = newUser;
        }
        const visitDetailsRequest = {
          customerId: registeredUserDetails?.id,
          vendorId: vendorId,
          configId: configId,
          propertyDetailsId: registeredUserDetails?.propertyDetails?.id,
          visitDate: new Date(formJson.visitDate).toISOString(),
          contactName: formJson.contactPerson,
          contactNumber: formJson.phoneNumber,
          address: formJson.fullAddress,
        };
        const visitResponse = await createVisitOpen(visitDetailsRequest);
        setIsAlert({ show: true, msg: 'Visit arranged successfully' });
        await sendSiteVisitInternalNotif(visitResponse.matchId);
        const tokens = await getTokens(registeredUserDetails);
        if (tokens) {
          localStorage.setItem('accessToken', tokens.accessToken);
          localStorage.setItem('refreshToken', tokens.refreshToken);
        }
        //This is not working
        //await updateIsConvertedStatus(recommendationId, true);
        handleClose(true, visitResponse.matchId);
      } else {
        //This flow is for authenticated users
        const match = await createMatch(
          propertyDetails?.userId,
          vendorId,
          configId,
        );
        const siteDetailsRequest = {
          propertyDetailsId: propertyDetails?.id,
          matchId: match.id,
          installationTimeline: propertyDetails?.installationTimeline,
          visitDate: new Date(formJson.visitDate).toISOString(),
          contactName: formJson.contactPerson,
          contactNumber: formJson.phoneNumber,
          address: formJson.fullAddress,
        };
        await createVisit(match.id, siteDetailsRequest);
        await updateIsConvertedStatus(recommendationId, true);
        setIsAlert({ show: true, msg: 'Visit arranged successfully' });
        await sendSiteVisitInternalNotif(match.id);
        handleClose(true, match.id);
      }
    } catch (error) {
      error.handled = true;
      if (error.response?.status === 403) {
        setIsAlert({
          show: true,
          msg: 'A site visit already exists for this property',
        });
      } else {
        setIsAlert({
          show: true,
          msg: 'Failed to arrange visit. Please try again later.',
        });
      }
      return;
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Dialog
        fullScreen={isMobile}
        sx={{
          borderRadius: '10px',
          '& .MuiDialog-paper': { borderRadius: '10px' },
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
          elevation: '2',
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(0.4px)',
              backgroundColor: 'rgba(0, 0, 0, 0.15)',
            },
          },
        }}
      >
        <DialogTitle
          display={'grid'}
          gap={'10px'}
          minWidth={'600px'}
          borderRadius={'10px'}
          sx={{ paddingBottom: 'unset' }}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={theme.typography.h5.fontSize}
            display={'flex'}
            mb={'24px'}
          >
            <EngineeringIcon fontSize='large' sx={{ mr: '18px' }} />
            Arrange visit
          </Typography>
          <Typography>
            {propertyDetails?.sanctionedLoad &&
              `${propertyDetails.sanctionedLoad} kW load`}
            {propertyDetails?.siteVisitData?.roofTopArea &&
              `, ${propertyDetails.siteVisitData.roofTopArea} sq ft`}
            {propertyDetails?.siteVisitData?.region &&
              ` @ ${propertyDetails.siteVisitData.region}`}
          </Typography>
          <PropertyDetails
            textVariant='primary'
            overrideAlignment='start'
            propertyDetails={propertyDetails}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <TextField
            id='full-address'
            name='fullAddress'
            label='Full address'
            type='text'
            variant='outlined'
            required
            multiline
            size='small'
            maxRows={4}
            slotProps={{ inputLabel: { shrink: true } }}
            margin='dense'
            value={formValues.fullAddress}
            onChange={handleInputChange}
            error={!!formErrors.fullAddress}
            helperText={formErrors.fullAddress}
          />
          <TextField
            id='date-for-visit'
            name='visitDate'
            label='Visit Date'
            InputLabelProps={{ shrink: true, required: false }}
            type='date'
            size='small'
            variant='outlined'
            margin='dense'
            value={formValues.visitDate}
            onChange={handleInputChange}
            error={!!formErrors.visitDate}
            helperText={formErrors.visitDate}
            InputProps={{
              onClick: (event) => {
                event.target.showPicker();
              },
            }}
          />
          <TextField
            id='contactperson'
            name='contactPerson'
            label='Contact person'
            type='text'
            variant='outlined'
            size='small'
            required
            slotProps={{ inputLabel: { shrink: true } }}
            margin='dense'
            value={formValues.contactPerson}
            onChange={handleInputChange}
            error={!!formErrors.contactPerson}
            helperText={formErrors.contactPerson}
          />
          <TextField
            required
            name='phoneNumber'
            type='tel'
            inputMode='numeric'
            size='small'
            placeholder='__________'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>+91</InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 10,
              style: { textAlign: 'left', letterSpacing: '0.1rem' },
            }}
            margin='dense'
            value={formValues.phoneNumber}
            onChange={handleInputChange}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
            variant='outlined'
          />
          <DialogActions
            sx={{
              padding: '30px 0px',
              justifyContent: 'right',
              gap: '15px',
              display: isMobile ? 'flex' : 'none',
            }}
          >
            <Button
              variant='outlined'
              onClick={() => handleClose(false)}
              startIcon={<CloseIcon />}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              type='submit'
              startIcon={
                submitting ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  <CheckIcon />
                )
              }
              disabled={submitting}
            >
              {submitting ? 'Loading...' : 'Arrange'}{' '}
            </Button>
          </DialogActions>
        </DialogContent>
        <DialogActions
          sx={{
            padding: '24px',
            justifyContent: 'right',
            gap: '15px',
            display: isMobile ? 'none' : 'flex',
          }}
        >
          <Button
            variant='outlined'
            onClick={() => handleClose(false)}
            startIcon={<CloseIcon />}
          >
            Cancel
          </Button>
          <Button variant='contained' type='submit' startIcon={<CheckIcon />}>
            Arrange
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
